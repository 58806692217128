import React from 'react';
import styled from 'styled-components';
import OpenNewIcon from '../../images/svg/open_new.svg';

const HeaderOuter = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  background: #fff;
`;

const Header = styled.div`
  padding: 48px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1135px;

  @media (max-width: 1135px) {
    padding: 48px 64px;
  }
  @media (max-width: 780px) {
    padding: 10px 20px 12px;
  }
  @media (max-width: 540px) {
    padding: 10px 16px 12px;
  }
  @media (max-width: 419px) {
    padding: 12px 16px 14px;
  }
  @media (max-width: 320px) {
    padding: 2px 12px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: baseline;

  color: #52463c;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  color: #52463c;
  display: flex;
  @media (max-width: 1135px) {
    font-size: 24px;
    letter-spacing: 0.01em;
    flex-direction: column;
  }
  @media (max-width: 550px) {
    font-size: 22px;
    flex-direction: column;
    align-items: flex-start;
    span {
      display: inline-block;
      line-height: 1.18;
    }
  }
  @media (max-width: 419px) {
    margin-top: 8px;
    font-size: 16px;
    flex-direction: column;
    align-items: flex-end;
    span {
      display: inline-block;
      line-height: 1.18;
    }
  }
  @media (max-width: 320px) {
    span {
      font-size: 14px;
    }
  }
`;

const Button = styled.div`
  padding: 19px 40px;
  background: linear-gradient(90deg, #ed7f19 0%, #f5b205 100%);
  border-radius: 8px;
  text-decoration: none;
  color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  @media (max-width: 540px) {
    padding: 12px 18px;
    letter-spacing: -0em;
  }
  @media (max-width: 419px) {
    padding: 10px 16px;
    letter-spacing: -0em;
  }
  @media (max-width: 320px) {
    font-size: 12px;
  }
`;

const TitleTop = styled.div`
  display: flex;
  @media (max-width: 500px) {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
  }
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: #1e89d7;
  line-height: 20.27px;
  height: 21px;
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  margin-left: 16px;
  span {
    color: #52463c;
  }
  img {
    vertical-align: middle;
  }
  @media (max-width: 430px) {
    margin-top: 2px;
    white-space: pre-wrap;
    font-size: 12px;
    line-height: 12px;
    img {
      width: 12px;
    }
    span {
      font-size: 12px;
    }
  }
  @media (max-width: 370px) {
    font-size: 10px;
    line-height: 10px;
    img {
      width: 10px;
    }
    span {
      font-size: 10px;
    }
  }
`;

const Transition = styled.div`
  position: relative;
  .active {
    visibility: visible;
    transition: all 400ms ease-in;
  }
  .hidden {
    visibility: hidden;
    transition: all 400ms ease-out;
    transform: translate(0, -100%);
  }
`;

const DXHeader = ({ show, scrollToForm }) => {
  return (
    <Transition>
      <HeaderOuter className={show ? 'active' : 'hidden'} id="dxheader">
        <Header>
          <Title>
            <TitleTop>
              <span>DX技術者</span>
              <span>育成プログラム</span>
            </TitleTop>

            <Link href="https://www.regnio.co.jp/" target="_blank">
              <div>
                <span>by</span> 株式会社Regnio
                <img src={OpenNewIcon} alt="icon" />
              </div>
            </Link>
          </Title>
          <div>
            <Button
              onClick={e => {
                scrollToForm(e);
              }}
            >
              資料請求・お問い合わせ
            </Button>
          </div>
        </Header>
      </HeaderOuter>
    </Transition>
  );
};

export default DXHeader;
