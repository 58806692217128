import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import RegnioTeamFluidImages from '../../../images/gatsby-image/regnio-team-page/fluid-images';
import { interviews } from '../constants';
import OpenNewIcon from '../../../images/svg/open_new.svg';

const Header = styled.div`
  height: 122px;
  background: linear-gradient(90deg, #1926a0 0%, #481b75 100%);
  color: #fff;
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  line-height: 122px;

  .small {
    font-size: 24px;
  }
  .large {
    font-size: 50px;
  }
  @media (max-width: 460px) {
    height: 62px;
    font-size: 28px;
    line-height: 62px;

    .small {
      font-size: 18px;
      line-height: 18px;
    }
    .large {
      font-size: 40px;
      line-height: 40px;
    }
  }
`;

const Image = styled(Img)`
  width: 642px;
  @media (max-width: 642px) {
    width: 100vw;
  }
`;

const Wrapper = styled.div`
  background: rgba(255, 233, 153, 0.15);
  padding-bottom: 60px;
`;

const InterviewWrapper = styled.div`
  position: relative;
  max-width: 1135px;
  margin: 0 auto;
  padding-bottom: 50px;
  .interview-box:nth-child(3) .interview-person {
    left: 0;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #52463c;
  position: absolute;
  top: 42px;
  @media (max-width: 1080px) {
    position: relative;
    top: 0;
    text-align: center;
    padding: 16px 0;
  }
  @media (max-width: 460px) {
    font-size: 16px;
    line-height: 23px;
  }
`;

const InterviewBox = styled.div`
  display: flex;
  height: 700px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 50px;
  &:nth-child(3) {
    flex-direction: row-reverse;
  }
  @media (max-width: 1080px) {
    height: auto;
    position: relative;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 0px;

    &:nth-child(3) {
      flex-direction: column-reverse;
    }
  }
`;

const InterviewText = styled.div`
  font-size: 16px;
  line-height: 29px;
  font-feature-settings: 'halt' on;
  color: #52463c;
  background: #fff;
  width: 528px;
  padding: 40px 60px 40px 40px;
  .title {
    font-weight: bold;
    font-size: 28px;
    line-height: 42px;
    color: #52463c;
    margin: 48px 0 31px 0;
  }
  p {
    font-size: 16px;
    line-height: 29px;
    margin-bottom: 16px;
  }
  a {
    color: #1e89d7;
    text-decoration: none;
    display: inline-flex;
  }
  position: absolute;
  z-index: 10;
  top: 120px;
  @media (max-width: 1135px) {
    padding: 20px 30px 20px 20px;
  }
  @media (max-width: 1080px) {
    position: relative;
    top: 0;
    width: 642px;
    box-sizing: border-box !important;
    .title {
      color: #0007ae;
      margin: 0 0 100px 0;
    }
  }
  @media (max-width: 642px) {
    width: 100%;
    padding: 20px 16px;
  }

  @media (max-width: 510px) {
    .title {
      font-size: 24px;
      line-height: 36px;
    }
  }
`;

const InterviewPerson = styled.div`
  position: absolute;
  right: 0;
  .person {
    margin-top: 24px;
    margin-left: 150px;
  }
  p {
    font-size: 16px;
    line-height: 16px;
  }
  .name {
    margin-top: 13px;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
  }
  @media (max-width: 1080px) {
    position: relative;
    color: #0007ae;
    .person {
      position: absolute;
      z-index: 10;
      bottom: -120px;
      margin-left: 20px;
    }
  }
  @media (max-width: 1080px) {
    p {
      font-weight: normal;
    }
    .name {
      span {
        font-weight: normal;
        margin-right: 8px;
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
  @media (max-width: 450px) {
    .person {
      bottom: -150px;
    }
  }
`;

const FooterWrapper = styled.div`
  margin: 0 auto;
  color: #ed7f19;
  display: flex;
  justify-content: center;
  align-items: end;
  text-align: center;
  max-width: 1135px;
  border-top: 4px double #ed7f19;
  border-bottom: 4px double #ed7f19;
  padding: 16px 0 12px 0;
  width: 95%;
  font-size: 36px;
  font-weight: 700;
  .pseudo {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
  }
  span {
    font-size: 24px;
    line-height: 51px;
  }
  @media (max-width: 610px) {
    margin: 0 16px;
    width: auto;
    flex-direction: column;
    align-items: center;
    .pseudo {
      margin-bottom: 12px;
      span {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
`;

const OkanoInterviews = ({ width }) => {
  return (
    <div>
      <Header>
        現在<span className="small">既に</span>
        <span className="large">2</span>名<span className="small">の</span>育成
        <span className="small">を</span>実施中!
      </Header>
      <Wrapper>
        <InterviewWrapper>
          <Title>実際にプログラムを受けての感想</Title>
          {interviews.map(interview => {
            return (
              <InterviewBox
                className="interview-box"
                key={`interview-${interview.name}`}
              >
                <InterviewText>
                  <div className="title">{interview.title}</div>
                  {interview.contents.map((content, i) => {
                    return (
                      <div key={content}>
                        <p>
                          {content}
                          {i === 0 && (
                            <a
                              href={interview.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              ( 詳しくはこちら
                              <img
                                src={OpenNewIcon}
                                alt={`${content.name}の記事を開く`}
                              />{' '}
                              )
                            </a>
                          )}
                        </p>
                      </div>
                    );
                  })}
                </InterviewText>
                <InterviewPerson className="interview-person">
                  <div>
                    <Image fluid={RegnioTeamFluidImages(interview.image)} />
                    {width > 600 ? (
                      <div className="person">
                        <p>
                          {interview.company} {interview.department}
                        </p>

                        <div className="name">{interview.name}</div>
                      </div>
                    ) : (
                      <div className="person">
                        <p>{interview.company}</p>
                        <div className="name">
                          <span>{interview.department}</span>
                          {interview.name}
                        </div>
                      </div>
                    )}
                  </div>
                </InterviewPerson>
              </InterviewBox>
            );
          })}
        </InterviewWrapper>
        <FooterWrapper>
          {width > 600 ? (
            <>
              <div className="pseudo">
                <div>社長への</div>
                <div>成果発表の場にて</div>
              </div>
              <div>
                追加<span>で</span>更<span>に</span>2名<span>の</span>育成
                <span>が</span>決定!!
              </div>
            </>
          ) : (
            <>
              <div className="pseudo">
                <div>
                  社長への成果発表の場にて<span>追加</span>で<span>更</span>に
                </div>
              </div>
              <div>
                2名<span>の</span>育成
                <span>が</span>決定!!
              </div>
            </>
          )}
        </FooterWrapper>
      </Wrapper>
    </div>
  );
};

export default OkanoInterviews;
