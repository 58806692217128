import React from 'react';
import styled from 'styled-components';
import { Modal, Paper } from '@material-ui/core';
import closeSrc from '../../images/svg/lp_close_button.svg';
import DXPrivacyPolicy from './dx-privacy-policy';

const ModalContainer = styled(Modal)`
  display: grid;
  place-items: center;
  z-index: 10000;
`;

const ModalBodyContainer = styled(Paper)`
  width: 90vw;
  height: 90vh;
  margin: 0 auto;
  box-sizing: border-box !important;
  padding: 0 46px 36px 46px;
  box-sizing: border-box !important;
  overflow-y: auto;
  @media (max-width: 500px) {
    width: 95vw;
    padding: 0 16px 36px 16px;
  }
`;

const ModalHeader = styled.div`
  background: #fff;
  display: flex;
  position: sticky;
  width: 100%;
  top: 0;
  padding: 36px 0;
  justify-content: space-around;
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #1a1007;
`;

const CloseButton = styled.div`
  margin-left: auto;
  cursor: pointer;
`;

const ModalBody = styled.div`
  background: rgba(0, 124, 194, 0.04);
  color: #4f4f4f;
  padding: 24px 0;
`;

const DXModal = ({ open, handleClose }) => {
  return (
    <ModalContainer
      open={open}
      onClose={handleClose}
      aria-labelledby="プライバシーポリシーモーダル"
      aria-describedby="プライバシーポリシーモーダル"
    >
      <ModalBodyContainer>
        <ModalHeader>
          <Heading>プライバシーポリシー</Heading>
          <CloseButton aria-hidden="true" onClick={() => handleClose()}>
            <img src={closeSrc} alt="モーダルを閉じる" />
          </CloseButton>
        </ModalHeader>
        <ModalBody>
          <DXPrivacyPolicy />
        </ModalBody>
      </ModalBodyContainer>
    </ModalContainer>
  );
};

export default DXModal;
