import React from 'react';
import styled from 'styled-components';
import OpenNewIcon from '../../images/svg/lp_open_window_w.svg';

const Wrapper = styled.div`
  background: #52463c;
  color: #fff;
`;

const Container = styled.div`
  max-width: 1135px;
  margin: 0 auto;
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 840px) {
    width: 90%;
    align-items: flex-start;
    flex-direction: column-reverse;
  }
`;

const TextContents = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  white-space: pre-wrap;
  span {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  fill: #fff;
`;

const Link = styled.a`
  text-decoration: none;
  color: #fff;
  cursor: pointer;
`;

const DXFooter = ({ modalOpen }) => {
  return (
    <Wrapper>
      <Container>
        <TextContents>
          <Link href="https://www.regnio.co.jp/" target="_blank">
            株式会社Regnio（リグニオ）
            <Icon src={OpenNewIcon} alt="icon" />
          </Link>
          {'\n'}
          〒810-0023 福岡県福岡市中央区警固2-2-23 ウイングコート警固 601{'\n'}
        </TextContents>
        <TextContents
          onClick={() => {
            modalOpen();
          }}
        >
          <span>プライバシーポリシー</span>
        </TextContents>
      </Container>
    </Wrapper>
  );
};

export default DXFooter;
