import React from 'react';
import styled, { keyframes } from 'styled-components';
import Img from 'gatsby-image';
import RegnioTeamFluidImages from '../../images/gatsby-image/regnio-team-page/fluid-images';
import OpenNewIcon from '../../images/svg/open_new.svg';

const WipeColor = keyframes`
  0% {
    color: transparent;
  }
  50% {
    color: transparent;
  }
  51% {
    color: #0007AE;
    background: #fff;
  }
  100% {
    color: #0007AE;
    background: #fff;
  }
`;

const WipeBar = keyframes`
0% {
    left: 0;
    right: 100%;
  }
  50% {
    left: 0;
    right: 0;
  }
  100% {
    left: 100%;
    right: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-position: right;
  height: 630px;
  padding: 150px 20px 0 20px;
  width: inherit;
  max-width: 1135px;
  color: #52463c;
  margin: 0 auto 80px auto;
  @media (max-width: 1135px) {
    margin: 0 auto;
  }
  @media (max-width: 780px) {
    height: 654px;
    padding: 74px 16px 0 16px;
  }
  @media (max-width: 540px) {
    padding: 74px 16px 0 16px;
    height: 630px;
  }
  @media (max-width: 419px) {
    padding: 58px 8px 0 8px;
    height: 780px;
    margin: 0 auto 32px auto;
  }
  @media (max-width: 320px) {
    padding: 54px 8px 0 8px;
    height: 772px;
    /* margin-bottom: 32px; */
  }
`;

const FlexContainer = styled.div`
  display: flex;
  @media (max-width: 419px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftContainer = styled.div`
  z-index: 10;
  text-align: left;

  @media (max-width: 540px) {
    /* margin-left: 16px; */
    order: 2;
    position: absolute;
    top: 179px;
    width: fill-available;
  }
  @media (max-width: 419px) {
    margin-left: 16px;
    order: 2;
    position: absolute;
    top: 334px;
  }
  @media (max-width: 320px) {
    margin-left: 8px;
    top: 332px;
  }
`;

const RightContainer = styled.div`
  position: absolute;
  right: 0;
  width: 65%;
  @media (max-width: 540px) {
    width: 100vw;
    order: 1;
  }
`;

const Container = styled.div`
  padding: 32px 16px 32px 0;
  @media (max-width: 1135px) {
  }
  @media (max-width: 419px) {
    padding: 0;
    margin-bottom: 40px;
  }
`;

const PTextContainer = styled.div`
  white-space: pre-wrap;
  font-size: 15px;
  line-height: 27px;
  background: #fff;
  width: max-content;
  @media (max-width: 540px) {
    white-space: normal;
    display: inline;
  }
`;

const Heading = styled.div`
  width: fit-content;
  margin-bottom: 14px;
  color: #fff;
  background: #242899;
  font-weight: bold;
  font-size: 50px;
  line-height: 50px;
  padding: 8px;
  span {
    font-size: 34px;
  }
  @media (max-width: 540px) {
    font-size: 30px;
    line-height: 1;
    padding-right: 0;
    span {
      display: inline-block;
      font-size: 22px;
      padding: 0 3px;
    }
  }
`;

const HText = styled.div`
  position: relative;
  color: transparent;
  width: max-content;
  font-weight: bold;
  font-size: 36px;
  line-height: 50px;
  animation: ${WipeColor} 0.7s ease-in-out both;
  &:after {
    display: block;
    content: '';
    height: 100%;
    background: #0007ae;
    position: absolute;
    top: 0;
    animation: ${WipeBar} 0.7s ease-in-out both;
  }
  span {
    font-size: 30px;
    line-height: 50px;
  }
  span.dx {
    font-size: 36px;
    vertical-align: text-bottom;
  }
  p.sesize {
    display: inline-block;
    font-weight: bold;
  }

  @media (max-width: 540px) {
    font-size: 24px;
    line-height: 26px;
    span {
      font-size: 18px;
      line-height: 1.88;
    }
    span.dx {
      font-size: 26px;
      line-height: 30px;
      vertical-align: text-bottom;
    }
    span.sp {
      font-size: 18px;
    }
    @media (max-width: 320px) {
      font-size: 22px;
      span.sp {
        font-size: 22px;
      }
      div.seParagraph {
        display: flex;
        flex-direction: column;
      }
      p.sesize {
        display: inline-block;
      }
    }
  }
`;
const ButtonContainer = styled.div`
  padding: 32px 0;
  @media (max-width: 1135px) {
    position: absolute;
    bottom: 70px;
    /* position: relative; */
  }
  @media (max-width: 780px) {
    position: absolute;
    top: 554px;
    left: calc(100vw / 2 - 256px);
  }
  @media (max-width: 540px) {
    position: absolute;
    top: 396px;
    left: calc(100vw / 2 - 175px);
    padding: 0;
    margin-left: 0;
  }

  @media (max-width: 419px) {
    top: 400px;
  }
  @media (max-width: 320px) {
    top: 452px;
    left: calc(100vw / 2 - 145px);
  }
`;

const Button = styled.a`
  padding: 30px 146px;
  background: linear-gradient(90deg, #ed7f19 0%, #f5b205 100%);
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  margin-top: 32px;
  cursor: pointer;
  @media (max-width: 1135px) {
    padding: 30px 124px;
  }
  @media (max-width: 540px) {
    padding: 19px 60px;
    font-size: 18px;
  }
  @media (max-width: 320px) {
    padding: 19px 38px;
    font-size: 18px;
  }
`;

const PContainerOuter = styled.div`
  @media (max-width: 1135px) {
    position: absolute;
    width: 484px;
    /* left: 16px; */
  }
  @media (max-width: 540px) {
    width: 95%;
  }
  @media (max-width: 320px) {
    /* width: 100%; */
    left: 0;
  }
`;

const PContainer = styled.div`
  margin: 12px;
  @media (max-width: 540px) {
    margin: 12px 0;
  }
  @media (max-width: 320px) {
    margin: 12px 0;
  }
`;

const Image = styled(Img)`
  max-width: 803px;
  @media (max-width: 540px) {
    height: 300px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: #1e89d7;
  line-height: 20.27px;
  height: 21px;
  cursor: pointer;

  width: max-content;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  padding: 15px 0;

  @media (max-width: 780px) {
    position: absolute;
    top: 580px;
  }
  @media (max-width: 540px) {
    top: 550px;
    left: calc(100vw / 2 - 126.6px);
  }
  @media (max-width: 419px) {
    top: 734px;
    /* left: calc(100vw / 2 - 126.6px); */
  }
  @media (max-width: 320px) {
    top: 734px;
  }
`;

const DXHero = ({ scrollToForm }) => {
  return (
    <Wrapper>
      <FlexContainer>
        <LeftContainer>
          <Container>
            <Heading>
              事業変革<span>の</span>実現<span>へ。</span>
            </Heading>
            <div>
              <HText>
                御社<span>の</span>社員<span>を</span>出向<span>で</span>
                受け入れ
              </HText>
              <HText>
                リアル<span>な</span>開発現場<span>で</span>
              </HText>
              <HText>
                <div className="seParagraph">
                  <p className="sesize">
                    <span className="sp">変革</span>
                    <span>の</span>
                    <span className="sp">要</span>
                    <span>となる</span>
                  </p>
                  <p className="sesize">
                    <span className="dx">DX</span>
                    技術者<span>に</span>育<span>てます。</span>
                  </p>
                </div>
              </HText>
            </div>
            <PContainerOuter>
              <PContainer>
                <PTextContainer>株式会社Regnio（リグニオ）は</PTextContainer>
                <PTextContainer>
                  最新のテクノロジーを使って事業変革（DX）を請け負っており、
                </PTextContainer>
                <PTextContainer>
                  企業から人材育成目的での出向を受け入れています。
                </PTextContainer>
              </PContainer>
              <PContainer>
                <PTextContainer>
                  実際に「御社で使うシステム」を作ることを通して、
                </PTextContainer>
                <PTextContainer>
                  出向する社員の方をITエンジニアとして育成します。
                </PTextContainer>
              </PContainer>
            </PContainerOuter>
          </Container>
          <ButtonContainer>
            <Button
              onClick={e => {
                scrollToForm(e);
              }}
            >
              資料請求・お問い合わせ
            </Button>
          </ButtonContainer>
        </LeftContainer>
        <RightContainer>
          <Image fluid={RegnioTeamFluidImages('heroImage')} />
          <Link href="https://www.regnio.co.jp/" target="_blank">
            <div>
              by 株式会社Regnio <img src={OpenNewIcon} alt="icon" />
              （福岡県福岡市）
            </div>
          </Link>
        </RightContainer>
      </FlexContainer>
    </Wrapper>
  );
};

export default DXHero;
