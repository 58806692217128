import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import RegnioTeamFluidImages from '../../../images/gatsby-image/regnio-team-page/fluid-images';

const StepOneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  @media (max-width: 1134px) {
    width: 90%;
    margin: 0 auto;
  }
  @media (max-width: 1134px) {
    width: 90%;
    margin: 0 auto;
  }
  @media (max-width: 750px) {
    width: 100%;
    background: #fff;
    padding: 80px 16px;
    box-sizing: border-box !important;
  }
  @media (max-width: 550px) {
    padding: 80px 16px 40px 16px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 44px;
  line-height: 64px;
  color: #0007ae;
  margin-bottom: 40px;
  span {
    font-weight: 600;
    font-size: 48px;
    line-height: 65px;
    color: #52463c;
    position: relative;
    ::before {
      content: 'STEP';
      position: absolute;
      top: 0;
      left: 20%;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
    }
  }
  @media (max-width: 555px) {
    font-size: 30px;
    line-height: 32px;
    color: #0007ae;
    span {
      font-size: 24px;
      line-height: 24px;
      margin-right: 8px;
      ::before {
        content: 'STEP';
        position: absolute;
        top: -8px;
        left: 0%;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
      }
    }
  }
  @media (max-width: 350px) {
    margin-bottom: 20px;
  }
`;

const Fukidashi = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: max-content;
  padding: 24px;
  box-sizing: border-box !important;
  background: #1e89d7;
  border-radius: 16px;
  color: #ffffff;
  text-align: center;
  white-space: pre-wrap;
  position: relative;
  margin-right: 32px;
  ::after {
    content: '';
    position: absolute;
    margin: 0;
    bottom: -30px;
    right: 30px;
    width: 0;
    height: 0;
    border-top: 40px solid #1e89d7;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    transform: rotate(-30deg);
  }
  @media (max-width: 1134px) {
    margin: 0 0 10px -60%;
  }
  @media (max-width: 750px) {
    margin: 0;
    ::after {
      display: none;
    }
  }
  @media (max-width: 500px) {
    height: 80px;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
  }
`;

const ScheduleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 26px;
  color: #1e89d7;
  background: #dff2fc;
  width: 221px;
  height: 120px;
  white-space: pre-wrap;
  font-weight: bold;
  font-size: 18px;
  margin-right: 2px;
  @media (max-width: 1134px) {
    margin-top: 12px;
  }
  @media (max-width: 750px) {
    margin-top: 2px;
    width: auto;
    justify-content: flex-start;
    padding-left: 36px;
  }
  @media (max-width: 500px) {
    margin-top: 2px;
    font-size: 16px;
    line-height: 29px;
    padding-left: 16px;
  }
`;

const SPScheduleBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-left: 2px dashed #828282;
  margin: 10px 0 10px 10px;
  @media (max-width: 750px) {
    margin: 16px 0 16px 10px;
  }
  @media (max-width: 350px) {
    padding-left: 8px;
  }
`;

const DottedBorder = styled.div`
  border-bottom: 2px dashed #828282;
  flex-grow: 2;
  align-self: center;
  margin: 0 8px;
`;

const MonthsHeading = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  font-feature-settings: 'halt' on;
  color: #1e89d7;
  span {
    font-size: 40px;
  }
  @media (max-width: 1134px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    .vertical {
      writing-mode: vertical-rl;
    }
  }
  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 32px;
    span {
      font-size: 32px;
    }
  }
  @media (max-width: 500px) {
    font-size: 21px;
    line-height: 30px;
    span {
      font-size: 32px;
      line-height: 40px;
    }
  }
  @media (max-width: 350px) {
    flex-basis: 10%;
    font-size: 16px;
    line-height: 28px;
    span {
      font-size: 24px;
      line-height: 30px;
    }
  }
`;

const ScheduleCalender = styled(Box)`
  margin: 3px 0 13px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  font-feature-settings: 'halt' on;
  color: #828282;
`;

const Image = styled(Img)`
  width: 220px;
  margin-top: 28px;
  @media (max-width: 500px) {
    width: 121px;
  }
`;

const TextHeading = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #52463c;
  white-space: pre-wrap;
  margin: 16px 0;
  @media (max-width: 370px) {
    font-size: 21px;
  }
`;

const TextContents = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: #52463c;
  white-space: pre-wrap;
  p {
    margin: 8px 0;
  }
  span {
    font-weight: 700;
  }
`;

const SPTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  @media (max-width: 750px) {
    flex-direction: row;
  }
  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

const SPTopRightContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justifu-content: space-evenly;
  @media (max-width: 750px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
`;

const SPBottomTextContainer = styled.div`
  margin: 20px auto;
  width: 80%;
  @media (max-width: 500px) {
    width: 90%;
  }
`;

const SPScheduleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SPTerm = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  font-feature-settings: 'halt' on;
  color: #828282;
`;

const SPScheduleWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  @media (max-width: 1134px) {
    width: auto;
    margin: 0;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
  @media (max-width: 400px) {
    width: 95%;
  }
`;

const SPStepOneComponent = ({
  thisYear,
  firstMonth,
  secondMonth,
  secondYear,
}) => {
  return (
    <StepOneWrapper>
      <div>
        <Title>
          <span>01</span>ヒアリング期間
        </Title>
      </div>
      <Box display="flex" margin="0 auto" flexDirection="column">
        <SPTopContainer>
          <SPTopRightContainer>
            <Fukidashi>ヒアリングして{'\n'}スタート</Fukidashi>
            <div>
              <Image fluid={RegnioTeamFluidImages('illustGroupStudy')} />
            </div>
          </SPTopRightContainer>

          <SPScheduleWrapper>
            <SPScheduleContainer>
              <MonthsHeading>
                <div>
                  <span>1</span>ヶ月
                </div>
                <div className="vertical">〜</div>
                <div>
                  <span>2</span>ヶ月
                </div>
              </MonthsHeading>
              <Box flexBasis="100%">
                <SPTerm>
                  {thisYear}年{firstMonth}月
                </SPTerm>
                <SPScheduleBoxContainer>
                  <ScheduleBox>
                    <div>課題をヒアリング</div>
                  </ScheduleBox>
                  <ScheduleBox>
                    <div>理想のチーム像を明確化</div>
                  </ScheduleBox>
                </SPScheduleBoxContainer>
                <SPTerm>
                  {secondYear}年{secondMonth}月
                </SPTerm>
              </Box>
            </SPScheduleContainer>
          </SPScheduleWrapper>
        </SPTopContainer>

        <SPBottomTextContainer>
          <TextHeading>
            御社の解決したい課題と、{'\n'}
            目指したい組織体制を{'\n'}明確化します
          </TextHeading>
          <TextContents>
            <p>
              まずは現場の課題や業務内容についてヒアリングさせていただきます。
            </p>
            <p>
              各企業ごとに、抱えている課題・解決策は異なりますので、{'\n'}
              <span>求められる「エンジニア」像も様々</span>です。
            </p>
            <p>
              <span>御社がどんな組織体制を目指すのか</span>
              を丁寧にヒアリングし、最初にしっかりと
              <span>
                目指すゴール像、育成したいエンジニア像をすり合わせます。
              </span>
            </p>
          </TextContents>
        </SPBottomTextContainer>
      </Box>
    </StepOneWrapper>
  );
};

const StepOne = ({ width }) => {
  const date = new Date();
  const thisYear = new Date().getFullYear();
  const firstMonth = new Date().getMonth() + 1;
  const nextDate = new Date(date.setMonth(date.getMonth() + 2));
  const secondYear = nextDate.getFullYear();
  const secondMonth = nextDate.getMonth();

  return (
    <>
      {width < 1134 ? (
        <SPStepOneComponent
          thisYear={thisYear}
          firstMonth={firstMonth}
          secondYear={secondYear}
          secondMonth={secondMonth}
        />
      ) : (
        <StepOneWrapper>
          <div>
            <Title>
              <span>01</span>ヒアリング期間
            </Title>
          </div>
          <Box display="flex">
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Box display="flex" alignItems="center">
                <Fukidashi>ヒアリングして{'\n'}スタート</Fukidashi>
                <div>
                  <Box display="flex" flexDirection="column">
                    <MonthsHeading>
                      <span>1</span>ヶ月〜<span>2</span>ヶ月
                    </MonthsHeading>
                    <ScheduleCalender
                      display="flex"
                      justifyContent="space-between"
                    >
                      <span>
                        {thisYear}年{firstMonth}月
                      </span>
                      <DottedBorder />
                      <span>
                        {secondYear}年{secondMonth}月
                      </span>
                    </ScheduleCalender>
                  </Box>
                  <Box display="flex">
                    <ScheduleBox>
                      <div>課題を{'\n'}ヒアリング</div>
                    </ScheduleBox>
                    <ScheduleBox>
                      <div>理想のチーム像を{'\n'}明確化</div>
                    </ScheduleBox>
                  </Box>
                </div>
              </Box>
              <div>
                <Image fluid={RegnioTeamFluidImages('illustGroupStudy')} />
              </div>
            </Box>
            <Box mt={10} pt={5} ml={5}>
              <TextHeading>
                御社の解決したい課題と、{'\n'}
                目指したい組織体制を明確化します
              </TextHeading>
              <TextContents>
                <p>
                  まずは現場の課題や業務内容についてヒアリングさせていただきます。
                </p>
                <p>
                  各企業ごとに、抱えている課題・解決策は異なりますので、{'\n'}
                  <span>求められる「エンジニア」像も様々</span>です。
                </p>
                <p>
                  <span>御社がどんな組織体制を目指すのか</span>
                  を丁寧にヒアリングし、最初にしっかりと
                  <span>
                    目指すゴール像、{'\n'}
                    育成したいエンジニア像をすり合わせます。
                  </span>
                </p>
              </TextContents>
            </Box>
          </Box>
        </StepOneWrapper>
      )}
    </>
  );
};

export default StepOne;
