import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { ProjectTitle } from '../constants';
import StepOne from './step1';
import StepTwo from './step2';
import StepThree from './step3';
import StepFour from './step4';
import RegnioTeamFluidImages from '../../../images/gatsby-image/regnio-team-page/fluid-images';

const Wrapper = styled(BackgroundImage)`
  background: rgba(255, 233, 153, 0.15);
  background-repeat: repeat-y;
  background-size: 80%;
  background-position: center;
  padding-bottom: 40px;
  @media (max-width: 750px) {
    padding-bottom: 0;
    ::before {
      display: none !important;
    }
    ::after {
      display: none !important;
    }
  }
`;

const Container = styled.div`
  max-width: 1135px;
  margin: 0 auto;
`;

const Header = styled.div`
  background: linear-gradient(90deg, #1926a0 0%, #481b75 100%);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 222px;
  position: relative;
  padding: 29px 0;

  ::after {
    position: absolute;
    top: 99%;
    content: '';
    width: 0;
    height: 0;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-top: 50px solid #30218b;
    z-index: 10;
  }
  @media (max-width: 850px) {
    flex-direction: column;
    height: 289px;
  }
`;

const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin-right: 12px;
  @media (max-width: 850px) {
    flex-direction: row;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
`;

const Title = styled.div`
  font-size: 44px;
  line-height: 51px;
  font-weight: bold;
  span {
    margin-left: 6px;
    font-weight: bold;
    font-size: 32px;
    line-height: 51px;
  }
  @media (max-width: 680px) {
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    span {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }
  }
  @media (max-width: 680px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    span {
      margin-top: 10px;
    }
  }
  @media (max-width: 350px) {
    font-size: 26px;
    line-height: 21px;
  }
`;

const WhiteBox = styled.div`
  box-sizing: border-box !important;
  background: #fff;
  font-weight: bold;
  font-size: 32px;
  line-height: 51px;
  color: #0007ae;
  display: flex;
  align-items: center;
  margin-top: 23px;
  padding: 8px 20px;
  max-width: 762px;
  span {
    font-size: 24px;
  }
  @media (max-width: 850px) {
    span {
      white-space: pre-wrap;
      text-align: center;
    }
  }
  @media (max-width: 680px) {
    font-size: 23px;
    line-height: 30px;
    padding: 14px 25px;
    text-align: center;
    span {
      font-size: 14px;
      line-height: 14px;
    }
  }
  @media (max-width: 350px) {
    font-size: 18px;
    line-height: 21px;
    margin: 8px;
    padding: 14px 16px;
  }
`;

const SubHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 18px;
  color: #faff00;
  padding-top: 29px;
  @media (max-width: 850px) {
    white-space: pre-wrap;
    line-height: 26px;
    text-align: center;
  }
  @media (max-width: 680px) {
    font-size: 18px;
    line-height: 30px;
  }
  @media (max-width: 350px) {
    font-size: 16px;
    line-height: 21px;
    padding-top: 16px;
  }
`;

const DXSchedule = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <div>
      <Header>
        <HeaderTop>
          <FlexColumn>
            <div>エンジニアチーム</div>
            <div>社内組成プログラム</div>
          </FlexColumn>
          <Title>
            {ProjectTitle}
            <span>導入の流れ</span>
          </Title>
        </HeaderTop>
        <WhiteBox>
          <div>
            岡野バルブ製造株式会社<span>のケース</span>
            <span>を{'\n'}当てはめた</span>例
          </div>
        </WhiteBox>
        <SubHeading>
          ※各企業様のご都合に合わせて{'\n'}プログラム内容の調整が可能です。
        </SubHeading>
      </Header>
      <Wrapper fluid={RegnioTeamFluidImages('BgWave')}>
        <Container>
          <StepOne width={width} />
          <StepTwo width={width} />
          <StepThree width={width} />
          <StepFour width={width} />
        </Container>
      </Wrapper>
    </div>
  );
};

export default DXSchedule;
