import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Box } from '@material-ui/core';
import RegnioTeamFluidImages from '../../../images/gatsby-image/regnio-team-page/fluid-images';

const Wrapper = styled.div`
  background: #fff;
`;

const Container = styled.div`
  max-width: 1135px;
  margin: 0 auto;
`;

const Header = styled.div`
  color: #1e89d7;
  font-weight: 500;
  text-align: center;
  margin: 64px 0;
  @media (max-width: 500px) {
    margin: 31px 0;
  }
`;

const HeaderTopText = styled.div`
  font-size: 40px;
  line-height: 40px;
  span {
    font-size: 30px;
    line-height: 30px;
  }
  @media (max-width: 500px) {
    font-weight: 500;
    font-size: 34px;
    line-height: 34px;
    span {
      font-size: 24px;
      line-height: 24px;
    }
  }
`;

const HeaderBottomText = styled.div`
  font-size: 58px;
  line-height: 58px;
  span {
    font-size: 68px;
    line-height: 68px;
  }
  @media (max-width: 500px) {
    font-weight: 500;
    font-size: 44px;
    line-height: 44px;
    span {
      font-size: 52px;
      line-height: 52px;
    }
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 805px) {
    flex-direction: column;
    align-items: center;
    div {
      margin-left: 0;
    }
  }
  @media (max-width: 500px) {
    margin: 0 16px;
  }
`;

const ImageBox = styled.div`
  background: #fff9e4;
  height: 280px;
  padding: 16px;
  display: grid;
  place-items: center;
  @media (max-width: 805px) {
    height: 250px;
    width: 210px;
    padding: 16px;
  }
  @media (max-width: 500px) {
    width: 120px;
    height: 120px;
  }
  @media (max-width: 370px) {
    width: 100px;
    height: 100px;
  }
  @media (max-width: 340px) {
    width: 90px;
    height: 90px;
  }
`;

const TopImage = styled(Img)`
  width: 220px;
  height: 219px;
`;

const Image = styled(Img)`
  width: 220px;
  height: 219px;
  @media (max-width: 805px) {
    width: 180px;
  }
  @media (max-width: 570px) {
    width: 150px;
  }
  @media (max-width: 500px) {
    width: 80px;
    height: 90px;
  }
`;
const StudyImage = styled(Img)`
  width: 220px;
  height: 219px;
  @media (max-width: 805px) {
    width: 180px;
  }
  @media (max-width: 570px) {
    width: 150px;
  }
  @media (max-width: 570px) {
    height: 128px;
  }
`;

const TextContents = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: #52463c;
  font-weight: 500;
  white-space: pre-wrap;
  margin: 12px 0;
  padding: 8px;
  @media (max-width: 500px) {
    font-weight: normal;
    font-size: 16px;
    line-height: 29px;
    margin: 0;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 56px;
  @media (max-width: 805px) {
    grid-template-columns: 1fr;
    margin: 16px;
  }
  @media (max-width: 500px) {
    margin: 0 16px;
  }
`;

const GridContents = styled.div`
  @media (max-width: 805px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const GridTextContents = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: #52463c;
  font-weight: 500;
  white-space: pre-wrap;
  margin: 12px 0;
  padding: 8px;
  @media (max-width: 805px) {
    width: 250px;
    span {
      margin: 8px 0;
      color: #0007ae;
      font-weight: bold;
      font-size: 28px;
      line-height: 32px;
    }
  }
  @media (max-width: 500px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    span {
      font-size: 20px;
      line-height: 29px;
    }
  }
  @media (max-width: 340px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const BottomTextContainer = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: #4f4f4f;
  white-space: pre-wrap;
  background: rgba(0, 124, 194, 0.04);
  display: flex;
  padding: 24px 33px;
  max-width: 630px;
  ::before {
    content: '※';
  }
  div {
    margin-left: 8px;
  }
`;

const SPBottomTextContainer = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: #4f4f4f;
  white-space: pre-wrap;
  background: rgba(0, 124, 194, 0.04);
  display: flex;
  padding: 24px 33px;
  max-width: 90%;
  margin: 0 16px;
  align-items: center;
  box-sizing: border-box !important;
  figure {
    float: right;
    margin: 0 0 15px 15px;
  }
  figure img {
    max-width: 100%;
  }
`;

const DXFeatutreSkills = ({ width }) => {
  return (
    <Wrapper>
      <Container>
        <Header>
          <HeaderTopText>
            習得可能<span>な</span>
          </HeaderTopText>
          <HeaderBottomText>
            <span>IT</span>技術の一例
          </HeaderBottomText>
        </Header>
        <div>
          <TopContainer>
            <TopImage
              fluid={RegnioTeamFluidImages('illustStudy')}
              imgStyle={{ objectFit: 'contain' }}
            />
            <Box ml={7} mb={3}>
              <TextContents>
                株式会社Regnioは、{'\n'}
                ハード〜ソフト〜インフラまで{'\n'}
                幅広い領域での技術を使って日々DX業務に取り組んでおります。{'\n'}
              </TextContents>
              <TextContents>
                御社の目指す組織体系に合った形での、{'\n'}
                エンジニア育成のためのディレクションを{'\n'}
                オーダーメイドで行うことが可能です。{'\n'}
              </TextContents>
            </Box>
          </TopContainer>
          <GridContainer>
            <GridContents>
              <ImageBox>
                <Image
                  fluid={RegnioTeamFluidImages('skillWeb')}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ImageBox>

              <GridTextContents>
                Web / モバイルアプリの開発や{'\n'}
                サーバー / DB の構築を通じた{'\n'}
                <span>システム開発技術{'\n'}</span>
              </GridTextContents>
            </GridContents>
            <GridContents>
              <ImageBox>
                <Image
                  fluid={RegnioTeamFluidImages('skillIoT')}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ImageBox>
              <GridTextContents>
                エッジコンピューターや{'\n'}
                センサー/カメラを用いた{'\n'}
                <span>IoT開発技術</span>
                {'\n'}
              </GridTextContents>
            </GridContents>
            <GridContents>
              <ImageBox>
                <Image
                  fluid={RegnioTeamFluidImages('skillAI')}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ImageBox>
              <GridTextContents>
                機械学習やデータ分析を通じた{'\n'}
                <span>AI技術開発</span>
              </GridTextContents>
            </GridContents>
          </GridContainer>
          {width > 912 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              my={10}
            >
              <BottomTextContainer>
                <div>
                  限られた期間内（1年間）で、上記の全てを習得・体験できるわけではありません。
                  {'\n'}
                  これら幅広い技術の中から、御社の課題解決ができるエンジニアになるために必要な
                  技術は何か、ベテランエンジニアが選定し、アドバイスいたします。
                  {'\n'}
                  まずは「学習しながら自走できる」状態を目指していただきます。
                </div>
              </BottomTextContainer>
              <Image
                fluid={RegnioTeamFluidImages('illustLecture')}
                imgStyle={{ objectFit: 'contain' }}
              />
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              my={10}
            >
              <SPBottomTextContainer>
                <div>
                  <figure>
                    <StudyImage
                      className="sp-image"
                      fluid={RegnioTeamFluidImages('illustLecture')}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </figure>
                  ※限られた期間内（1年間）で、上記の全てを習得・体験できるわけではありません。
                  これら幅広い技術の中から、御社の課題解決ができるエンジニアになるために必要な
                  技術は何か、ベテランエンジニアが選定し、アドバイスいたします。
                  まずは「学習しながら自走できる」状態を目指していただきます。
                </div>
              </SPBottomTextContainer>
            </Box>
          )}
        </div>
      </Container>
    </Wrapper>
  );
};

export default DXFeatutreSkills;
