import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import RegnioTeamFluidImages from '../../../images/gatsby-image/regnio-team-page/fluid-images';
import { ProjectTitle } from '../constants';

const StepFourWrapper = styled.div`
  margin: 80px 0;
  max-width: 1135px;
  @media (max-width: 750px) {
    margin: 40px 0 0 0;
    padding-top: 20px;
    background: #fff;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 44px;
  line-height: 64px;
  color: #0007ae;
  text-align: right;
  margin-right: 80px;
  span {
    margin: 0 12px;
  }
  .step {
    font-weight: 600;
    font-size: 48px;
    line-height: 65px;
    color: #52463c;
    position: relative;
    ::before {
      content: 'STEP';
      position: absolute;
      top: 0;
      left: 20%;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
    }
  }
  @media (max-width: 750px) {
    display: flex;
    text-align: left;
    margin-right: 0px;
  }
  @media (max-width: 555px) {
    font-size: 30px;
    line-height: 32px;
    color: #0007ae;
    display: flex;
    align-items: baseline;
    span {
      margin: 0;
      white-space: pre-wrap;
    }
    .step {
      font-size: 24px;
      line-height: 24px;
      margin-right: 8px;
      margin: 0 8px;
      ::before {
        position: absolute;
        top: -8px;
        left: 0%;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
      }
    }
  }
  @media (max-width: 370px) {
    width: 100%;
  }
`;

const ImageContainer = styled(Box)`
  width: 178px;
`;

const SPImageContainer = styled(Box)`
  width: 178px;
  float: left;
  margin: 8px 8px 8px 0;
  @media (max-width: 540px) {
    width: 120px;
    margin: 8px 2px 8px 0;
  }
  @media (max-width: 350px) {
    width: 100px;
  }
`;

const Image = styled(Img)`
  width: 178px;
`;

const SPImage = styled(Img)`
  width: 178px;
  @media (max-width: 540px) {
    width: 120px;
  }
  @media (max-width: 540px) {
    width: 100px;
  }
`;

const TextHeading = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #52463c;
  white-space: pre-wrap;
  margin: 16px 0;
  @media (max-width: 350px) {
    font-size: 20px;
    line-height: 32px;
  }
`;

const TextHeading500 = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #52463c;
  margin: 16px 0;
`;

const TextContents = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: #52463c;
  white-space: pre-wrap;
  margin: 12px 0;
`;

const Fukidashi = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: max-content;
  padding: 24px;
  line-height: 26px;
  box-sizing: border-box !important;
  background: #1e89d7;
  border-radius: 16px;
  color: #ffffff;
  text-align: center;
  white-space: pre-wrap;
  position: relative;
  margin-right: 32px;
  ::after {
    content: '';
    position: absolute;
    margin: 0;
    bottom: -30px;
    right: 30px;
    width: 0;
    height: 0;
    border-top: 40px solid #1e89d7;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    transform: rotate(-30deg);
  }
  @media (max-width: 980px) {
    margin: 0;
    ::after {
      display: none;
    }
  }
`;

const ScheduleBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 26px;
  color: #1e89d7;
  background: #dff2fc;
  width: 221px;
  height: 120px;
  white-space: pre-wrap;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin-right: 2px;
  @media (max-width: 980px) {
    width: auto;
    margin-top: 2px;
    padding: 8px;
  }
  @media (max-width: 750px) {
    justify-content: flex-start;
    padding-left: 36px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 29px;
    padding-left: 16px;
  }
`;

const DottedBorder = styled.div`
  border-bottom: 2px dashed #828282;
  flex-grow: 2;
  align-self: center;
  margin: 0 8px;
`;

const MonthHeading = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  font-feature-settings: 'halt' on;
  color: #1e89d7;
  span {
    font-size: 40px;
  }
  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 32px;
    span {
      font-size: 32px;
    }
  }
  @media (max-width: 500px) {
    flex-basis: 10%;
  }
`;

const ScheduleCalender = styled(Box)`
  margin: 3px 0 13px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  font-feature-settings: 'halt' on;
  color: #828282;
`;

const BlueBorderBox = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  font-feature-settings: 'halt' on;
  color: #1e89d7;
  background: #fff;
  border: 2px solid rgba(0, 124, 194, 0.2);
  box-sizing: border-box !important;
  border-radius: 12px;
  max-width: 537px;
  padding: 20px;
  margin-top: 39px;
  @media (max-width: 900px) {
    margin-top: 2px;
    width: auto;
  }
`;

const CEOMessageTop = styled.div`
  text-align: center;
  margin-right: 48px;
  @media (max-width: 540px) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 0;
    margin-bottom: 16px;
  }
  @media (max-width: 350px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const CEOMessageBox = styled(Box)`
  background: #ffffff;
  border: 8px solid #f9f9f9;
  box-sizing: border-box !important;
  padding: 42px 48px 24px 39px;
  display: flex;
  max-width: 908px;
  margin: 0 auto;
  @media (max-width: 750px) {
    background: #f9f9f9;
  }
  @media (max-width: 540px) {
    flex-direction: column;
    padding: 42px 16px 24px 16px;
  }
`;

const CEOMessage = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 29px;
  color: #52463c;
  max-width: 590px;
  margin: 12px 0;
  span {
    color: #0007ae;
    font-weight: 700;
  }
`;
const SPScheduleBoxContainer = styled.div`
  padding-left: 20px;
  border-left: 2px dashed #828282;
  margin: 10px 0 10px 10px;
  @media (max-width: 750px) {
    margin: 16px 0 16px 10px;
  }
  @media (max-width: 350px) {
    padding-left: 8px;
  }
`;

const SPTextContainer = styled.div`
  width: 80%;
  box-sizing: border-box !important;
  margin-left: auto;
  @media (max-width: 980px) {
    margin: 0 auto;
  }
  @media (max-width: 550px) {
    width: 100%;
    padding: 0 16px;
  }
`;

const SPTopContainer = styled.div`
  @media (max-width: 980px) {
    max-width: 90%;
    margin: 16px auto 16px 15%;
  }
`;

const SPTerm = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  font-feature-settings: 'halt' on;
  color: #828282;
`;

const SPScheduleWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  @media (max-width: 500px) {
    width: 90%;
  }
  @media (max-width: 400px) {
    width: 95%;
  }
`;

const StepFour = ({ width }) => {
  const date = new Date();
  const year = new Date(date.setMonth(date.getMonth() + 12)).getFullYear();
  const nextYear = new Date(date.setMonth(date.getMonth() + 12)).getFullYear();
  const firstMonth = new Date(date.setMonth(date.getMonth() + 15)).getMonth();
  const secondMonth = new Date(date.setMonth(date.getMonth() + 23)).getMonth();

  const SPContainer = () => {
    return (
      <StepFourWrapper>
        <Box mx={2}>
          <Title>
            <span className="step">04</span>
            <span>DX技術者チーム{'\n'}組成サポート期間</span>
          </Title>
        </Box>
        <Box display="flex" flexDirection="column">
          <SPTopContainer>
            <Fukidashi>
              御社に戻って{'\n'}
              スタート
            </Fukidashi>
          </SPTopContainer>

          <SPScheduleWrapper>
            <Box display="flex" justifyContent="center" alignItems="center">
              <MonthHeading>
                <div>
                  <span>12</span>ヶ月間
                </div>
              </MonthHeading>
              <Box flexBasis="100%">
                <SPTerm>
                  {year}年{firstMonth}月
                </SPTerm>
                <SPScheduleBoxContainer>
                  <ScheduleBox>
                    <div>
                      ・開発体勢や評価制度の整備{'\n'}・チームの方向性付け
                    </div>
                  </ScheduleBox>
                  <ScheduleBox>
                    <div>
                      ・プロジェクトの企画立案{'\n'}・システムの設計と実装
                    </div>
                  </ScheduleBox>
                  <ScheduleBox>
                    <div>
                      ・システム運用体制の確立{'\n'}・人材採用の体制構築
                    </div>
                  </ScheduleBox>
                </SPScheduleBoxContainer>
                <SPTerm>
                  {nextYear}年{secondMonth}月
                </SPTerm>
              </Box>
            </Box>
          </SPScheduleWrapper>
        </Box>

        <SPTextContainer>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            my="20px"
          >
            <TextHeading>
              出向社員の帰社後は、{'\n'}
              エンジニアチームを作るためのサポートを行います
            </TextHeading>
            <Box>
              <SPImageContainer>
                <SPImage fluid={RegnioTeamFluidImages('illustLecture')} />
              </SPImageContainer>
              <TextContents>
                出向社員は帰社後、エンジニアとして継続的に勉強したり、
                日々プロジェクトに取り組んでいただきます。{'\n'}
                出向社員が御社に戻った後は、 弊社CTO、もしくは上級エンジニアが、
                社外CTOとして週1程度で御社内でのチームの指導をサポートします。
              </TextContents>
            </Box>
          </Box>
          <Box>
            <BlueBorderBox>
              <div>
                {ProjectTitle}
                は、未来への一手として、エンジニアチーム組成のための「種」を撒くプロジェクトです。帰社してからも、日々の学習と試行錯誤が必要になります。
              </div>
            </BlueBorderBox>
          </Box>
        </SPTextContainer>
        <CEOMessageBox mt={6}>
          <CEOMessageTop>
            <ImageContainer>
              <Image fluid={RegnioTeamFluidImages('MasaTalk')} />
            </ImageContainer>
            <Box ml={2}>
              <TextHeading500>中野 雅俊</TextHeading500>
              <TextContents>
                株式会社Regnio{'\n'}
                代表取締役兼CTO
              </TextContents>
            </Box>
          </CEOMessageTop>
          <Box>
            <CEOMessage>
              本サービスでは、お客様側社員の方には、弊社に出向してもらい実際の現場で毎日肩を並べながら技術を学んでもらうことを重要視しています。
            </CEOMessage>
            <CEOMessage>
              現代では、プログラミングを始めとしたエンジニアリング技術を学ぶには、書籍や学校、インターネット等、様々な機会や場があります。しかし、それらを通して実際のエンジニアリング現場で結果を出せるようになるのはかなり難しいのが実情です。
            </CEOMessage>
            <CEOMessage>
              また、
              <span>
                実際の仕事（実務）を通して学べることの量や範囲は、座学や趣味で学んだことのそれとは歴然の差があります。
              </span>
              そういった背景から、弊社への出向により、同じ開発チームの一員として、生きたリアルな技術を習得していってもらいます。
            </CEOMessage>
          </Box>
        </CEOMessageBox>
      </StepFourWrapper>
    );
  };

  return (
    <>
      {width < 980 ? (
        <SPContainer />
      ) : (
        <StepFourWrapper>
          <Title>
            <span className="step">04</span>
            <span>DX技術者チーム 組成サポート期間</span>
          </Title>
          <Box my={5} display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <Fukidashi>
                御社に戻って{'\n'}
                スタート
              </Fukidashi>
            </Box>
            <Box width="90%" margin="0 auto">
              <Box display="flex" flexDirection="column">
                <MonthHeading>
                  <span>12</span>ヶ月
                </MonthHeading>
                <ScheduleCalender display="flex" justifyContent="space-between">
                  <span>
                    {year}年{firstMonth}月
                  </span>
                  <DottedBorder />
                  <span>
                    {nextYear}年{secondMonth}月
                  </span>
                </ScheduleCalender>
              </Box>
              <Box display="flex">
                <ScheduleBox flexBasis="33%">
                  <div>
                    ・開発体勢や評価制度の整備{'\n'}・チームの方向性付け
                  </div>
                </ScheduleBox>
                <ScheduleBox flexBasis="33%">
                  <div>
                    ・プロジェクトの企画立案{'\n'}・システムの設計と実装
                  </div>
                </ScheduleBox>
                <ScheduleBox flexBasis="33%">
                  <div>・システム運用体制の確立{'\n'}・人材採用の体制構築</div>
                </ScheduleBox>
              </Box>
            </Box>
          </Box>
          <Box display="flex" ml="auto" width="80%">
            <ImageContainer mr={6}>
              <Image fluid={RegnioTeamFluidImages('illustLecture')} />
            </ImageContainer>
            <Box display="flex" flexDirection="column">
              <Box>
                <TextHeading>
                  出向社員の帰社後は、{'\n'}
                  エンジニアチームを作るためのサポートを行います
                </TextHeading>
                <TextContents>
                  出向社員は帰社後、エンジニアとして継続的に勉強したり、{'\n'}
                  日々プロジェクトに取り組んでいただきます。
                </TextContents>
                <TextContents>
                  出向社員が御社に戻った後は、{'\n'}
                  弊社CTO、もしくは上級エンジニアが、{'\n'}
                  週1程度で御社内でのチームの指導をサポートします。
                </TextContents>
              </Box>
              <BlueBorderBox>
                <div>
                  {ProjectTitle}
                  は、未来への一手として、エンジニアチーム組成のための「種」を撒くプロジェクトです。帰社してからも、日々の学習と試行錯誤が必要になります。
                </div>
              </BlueBorderBox>
            </Box>
          </Box>
          <CEOMessageBox mt={6}>
            <Box textAlign="center" mr={6}>
              <ImageContainer>
                <Image fluid={RegnioTeamFluidImages('MasaTalk')} />
              </ImageContainer>
              <Box>
                <TextHeading500>中野 雅俊</TextHeading500>
                <TextContents>
                  株式会社Regnio{'\n'}
                  代表取締役兼CTO
                </TextContents>
              </Box>
            </Box>
            <Box>
              <CEOMessage>
                本サービスでは、お客様側社員の方には、弊社に出向してもらい実際の現場で毎日肩を並べながら技術を学んでもらうことを重要視しています。
              </CEOMessage>
              <CEOMessage>
                現代では、プログラミングを始めとしたエンジニアリング技術を学ぶには、書籍や学校、インターネット等、様々な機会や場があります。しかし、それらを通して実際のエンジニアリング現場で結果を出せるようになるのはかなり難しいのが実情です。
              </CEOMessage>
              <CEOMessage>
                また、
                <span>
                  実際の仕事（実務）を通して学べることの量や範囲は、座学や趣味で学んだことのそれとは歴然の差があります。
                </span>
                そういった背景から、弊社への出向により、同じ開発チームの一員として、生きたリアルな技術を習得していってもらいます。
              </CEOMessage>
            </Box>
          </CEOMessageBox>
        </StepFourWrapper>
      )}
    </>
  );
};

export default StepFour;
