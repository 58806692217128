import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import RegnioTeamFluidImages from '../../images/gatsby-image/regnio-team-page/fluid-images';
import { ProjectTitle } from './constants';

const Wrapper = styled.div`
  background: #fff;
  margin-bottom: 64px;
`;

const Container = styled.div`
  max-width: 1135px;
  margin: 0 auto;
`;

const Header = styled.div`
  background: linear-gradient(90deg, #1926a0 0%, #481b75 100%);
  height: 122px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 500px) {
    height: 80px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 51px;
  @media (max-width: 500px) {
    font-size: 26px;
    line-height: 39px;
  }
  @media (max-width: 360px) {
    white-space: pre-wrap;
  }
`;

const TopContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
  @media (max-width: 820px) {
    flex-direction: column;
    margin-bottom: 16px;
  }
`;

const BodyHeading = styled.div`
  color: #0007ae;
  margin-top: 42px;
  margin-left: -102px;
  margin-bottom: 86px;
  text-align: left;
  div {
    background: #fff;
  }
  .heading1 {
    font-weight: bold;
    font-size: 28px;
    line-height: 48px;
    margin: 2px 0;
    width: fit-content;
    span {
      font-size: 48px;
    }
  }
  .heading2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    width: fit-content;
  }
  @media (max-width: 1135px) {
    margin-bottom: 0px;
  }
  @media (max-width: 820px) {
    margin-left: 16px;
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    top: -26px;
  }
  @media (max-width: 530px) {
    div {
      background: #0007ae;
      color: #fff;
      padding: 2px 9px;
    }
    .heading1 {
      span {
        font-size: 36px;
      }
    }
    .heading2 {
      background: #0007ae;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      width: fit-content;
    }
  }
  @media (max-width: 500px) {
    .heading1 {
      font-size: 20px;
      line-height: 20px;
      padding: 4px 6px;
      span {
        font-size: 28px;
      }
    }
    .heading2 {
      font-size: 16px;
      line-height: 24px;
    }
  }
  @media (max-width: 350px) {

    .heading1 {
      font-size: 18px;
      line-height: 18px;
      padding: 4px 6px;
      span {
        font-size: 24px;
      }
    }
    .heading2 {
      font-size: 14px;
      line-height: 20px;
    }
  }
  }
`;
const Image = styled(Img)`
  width: auto;
  max-height: 420px;
  @media (max-width: 820px) {
    max-height: fit-content;
  }
`;

const BodyLeft = styled.div`
  width: 50vw;
  @media (max-width: 820px) {
    width: 100%;
  }
`;

const BodyRight = styled.div`
  z-index: 10;
  position: relative;
`;

const CEOProfile = styled.div`
  margin: 120px 16px 0 16px;
  @media (max-width: 1135px) {
    margin: 24px 16px 0 16px;
  }
  @media (max-width: 820px) {
    margin: 120px 16px 0 16px;
  }
  @media (max-width: 500px) {
    margin: 72px 16px 0 16px;
  }
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 28px;
  line-height: 41px;
  span {
    margin-left: 26px;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
  }
  @media (max-width: 514px) {
    font-weight: 300;
    font-size: 24px;
    line-height: 24px;
    color: #0007ae;
    span {
      margin-left: 9px;
      font-size: 16px;
      line-height: 16px;
    }
  }
`;

const TextContents = styled.div`
  font-size: 16px;
  line-height: ${props => (props.company ? '32px' : '24px')};
  color: #52463c;
  white-space: pre-wrap;
  margin: 12px 0;
  padding: 8px 0;
  span {
    font-weight: 700;
  }
  @media (max-width: 514px) {
    font-weight: ${props => (props.company ? '300' : 'normal')};
    font-size: 14px;
    line-height: 21px;
    color: ${props => (props.company ? '#0007ae' : '#52463c')};
    span {
      margin-left: 9px;
      font-size: 16px;
      line-height: 16px;
    }
  }
`;

const TimelineContainer = styled.div`
  display: flex;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const TimelineLeft = styled(Timeline)`
  margin-top: -200px;
  .MuiTimelineItem-missingOppositeContent:before {
    flex: 0;
  }
  @media (max-width: 820px) {
    margin-top: 0;
  }
  @media (max-width: 550px) {
    .MuiTimelineItem-missingOppositeContent:before {
      padding: 0;
    }
  }
`;

const TimelineRight = styled(Timeline)`
  .MuiTimelineItem-missingOppositeContent:before {
    flex: 0;
  }
  @media (max-width: 550px) {
    margin-top: -8px;
    padding: 0 16px;
    .MuiTimelineItem-missingOppositeContent:before {
      padding: 0;
    }
  }
`;

const TimelineGreyDot = styled(TimelineDot)`
  border-color: #52463c !important;
  border-width: 3px !important;
`;

const TimelineGreyConnecter = styled(TimelineConnector)`
  background-color: #52463c !important;
`;

const DXCEOProfile = () => {
  return (
    <Wrapper>
      <Header>
        <Title>指導責任者代表{'\n'}プロフィール</Title>
      </Header>
      <Container>
        <TopContainer>
          <BodyLeft>
            <Image
              fluid={RegnioTeamFluidImages('masaProfile')}
              imgStyle={{ objectFit: 'contain' }}
              alt="RegnioCEO"
            />
          </BodyLeft>
          <BodyRight>
            <BodyHeading>
              <div className="heading1">
                <span>経営課題</span>と<span>現場の課題</span>に
              </div>
              <div className="heading1">
                <span>深</span>く<span>寄</span>り<span>添</span>いながら
              </div>
              <div className="heading2">
                サービスを提供することを大切にしています
              </div>
            </BodyHeading>
            <CEOProfile>
              <TextContents company>
                株式会社Regnio{'\n'}
                代表取締役兼CTO
              </TextContents>
              <Name>
                中野 雅俊<span>なかの まさとし</span>
              </Name>

              <TextContents>
                ハードからソフトまで、{'\n'}
                幅広くエンジニアリング技術を駆使して{'\n'}
                日々老舗企業のDX事業に向き合っている。{'\n'}
              </TextContents>

              <TextContents>
                様々な企業に共通する{'\n'}
                IT人材不足の悩み相談に応えるソリューションとして{'\n'}
                <span>{ProjectTitle}</span>をサービス化。{'\n'}
              </TextContents>
            </CEOProfile>
          </BodyRight>
        </TopContainer>
        <TimelineContainer>
          <TimelineLeft align="left">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineGreyDot variant="outlined" />
                <TimelineGreyConnecter />
              </TimelineSeparator>
              <TimelineContent>
                <div>
                  1987年生まれ
                  <TextContents>
                    九州大学電気情報工学科卒業{'\n'}
                    九州大学総合理工学府量子プロセス理工学修了
                  </TextContents>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineGreyDot variant="outlined" />
                <TimelineGreyConnecter />
              </TimelineSeparator>
              <TimelineContent>
                <div>
                  日本無線
                  <TextContents>
                    イスラエルのベンチャー企業との{'\n'}
                    4G通信システムを共同開発{'\n'}
                    トンガのベンチャー企業と通信事業立ち上げ{'\n'}
                  </TextContents>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineGreyDot variant="outlined" />
                <TimelineGreyConnecter />
              </TimelineSeparator>
              <TimelineContent>
                <div>
                  ABeam Consulting
                  <TextContents>
                    アメリカのベンチャー企業と衛星写真を用いた{'\n'}
                    データ分析システムの開発
                  </TextContents>
                </div>
              </TimelineContent>
            </TimelineItem>
          </TimelineLeft>
          <TimelineRight align="left">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineGreyDot variant="outlined" />
                <TimelineGreyConnecter />
              </TimelineSeparator>
              <TimelineContent>
                <div>
                  株式会社QUANDO
                  <TextContents>
                    代表取締役CTOとして{'\n'}
                    老舗企業DX、自社プロダクト開発、組織運営及び人材育成を行う
                    {'\n'}
                    事業ドメインを分けるために分社し{'\n'}
                    株式会社Regnioを設立。{'\n'}
                  </TextContents>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineGreyDot variant="outlined" />
                <TimelineGreyConnecter />
              </TimelineSeparator>
              <TimelineContent>
                <div>
                  株式会社Regnio
                  <TextContents>
                    代表取締役兼CTOとして{'\n'}
                    老舗企業のDX業務、{'\n'}
                    自社プロダクト開発、組織運営及び人材育成を行う
                  </TextContents>
                </div>
              </TimelineContent>
            </TimelineItem>
          </TimelineRight>
        </TimelineContainer>
      </Container>
    </Wrapper>
  );
};

export default DXCEOProfile;
