import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled, { keyframes } from 'styled-components';
// import Collapse from '@material-ui/core/Collapse';
import { Box } from '@material-ui/core';
import StepOneSrc from '../../images/svg/lp-step1.svg';
import StepTwoSrc from '../../images/svg/lp-step2.svg';
import StepThreeSrc from '../../images/svg/lp-step3.svg';
import BorderArrowSrc from '../../images/svg/lp-border-arrow.svg';

const Wrapper = styled.div`
  background: #f5f3ec;
  @media (max-width: 650px) {
    background: #fff;
  }
`;

const Header = styled.div`
  background: linear-gradient(90deg, #1926a0 0%, #481b75 100%);
  height: 122px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ::after {
    position: absolute;
    top: 100%;
    content: '';
    width: 0;
    height: 0;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-top: 50px solid #30218b;
  }
  @media (max-width: 500px) {
    height: 90px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 51px;

  .small {
    margin-left: 6px;
    font-size: 24px;
  }
  @media (max-width: 650px) {
    span {
      display: none;
    }
  }
  @media (max-width: 500px) {
    font-size: 30px;
    line-height: 32px;
  }
  @media (max-width: 350px) {
    font-size: 24px;
    line-height: 26px;
  }
`;

const Container = styled.div`
  max-width: 1135px;
  margin: 0 auto;
  padding: 80px 0;
  @media (max-width: 650px) {
    padding: 70px 0 20px 0;
  }
`;

const FormWrapper = styled.div`
  background: #ffffff;
  max-width: 908px;
  margin: 0 auto;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  box-sizing: border-box !important;
  @media (max-width: 650px) {
    max-width: 100%;
    box-shadow: none;
  }
`;

const FormContainer = styled.div`
  max-width: 537px;
  margin: 0 auto;
  padding: 40px 0;
  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
  }
  .required {
    color: #e74646;
  }
  .optional {
    color: #1e89d7;
  }
  @media (max-width: 650px) {
    margin: 0 16px;
    padding: 20px 0;
  }
`;

const FormLabel = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 14px;
`;

const FormInput = styled.input`
  background: ${props =>
    props.error ? 'rgba(231, 70, 70, 0.08);' : '#fcfcfc'};
  border: ${props => (props.error ? '1px solid #E74646' : '1px solid #d2d1d1')};
  max-width: 352px;
  height: 44px;
  padding: 10px 0 10px 12px;
  box-sizing: border-box !important;
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.error ? '#E74646' : '#1a1007')};
`;

const FormTextArea = styled.textarea`
  background: #fcfcfc;
  border: 1px solid #d2d1d1;
  resize: none;
  min-height: 160px;
  max-width: 537px;
  padding: 10px 12px 0px 12px;
  box-sizing: border-box !important;
  font-size: 16px;
  line-height: 24px;
  color: #1a1007;
  @media (max-width: 650px) {
    width: 100%;
  }
`;

const FormFlexColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

const FormCheckBox = styled.input`
  background: #fcfcfc;
  border: 1px solid #d2d1d1;
  margin-right: 14px;
`;
const FormCenterBox = styled.div`
  text-align: center;
  margin: 20px;
`;

// 以下、日時相談用のスタイリング

// const FormDateContainer = styled.div`
//   background: rgba(255, 233, 153, 0.15);
//   padding: 24px 32px;
// `;

// const DateLabel = styled.div`
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 16px;
//   color: #52463c;
// `;

// const DateContainer = styled.div`
//   display: flex;
//   align-items: center;
//   font-weight: 300;
//   font-size: 16px;
//   line-height: 16px;
// `;

// const DateRowContainer = styled.div`
//   display: flex;
//   align-items: center;

//   margin: 20px 0;
//   .label {
//     margin-top: 40px;
//   }
// `;

// const DateFlexRow = styled.div`
//   display: flex;
//   align-items: flex-end;
// `;

// const DateInput = styled.input`
//   background: #fcfcfc;
//   border: 1px solid #d2d1d1;
//   height: 44px;
//   width: 32px;
//   margin: 0 9px;
//   padding: 10px 6px;
//   font-size: 16px;
//   line-height: 24px;
//   color: #1a1007;
//   box-sizing: border-box!important;
// `;

// const NoteLabel = styled.div`
//   .note {
//     font-weight: normal;
//     font-size: 16px;
//     line-height: 16px;
//     margin-left: 8px;
//     margin-bottom: 18px;
//     text-align: left;
//     color: #52463c;
//   }
//   .hidden {
//     display: none;
//   }
// `;

// const NoteInput = styled.input`
//   background: #fcfcfc;
//   border: 1px solid #d2d1d1;
//   height: 44px;
//   width: 247px;
//   margin: 0 9px;
//   padding: 0 8px;
//   font-size: 16px;
//   box-sizing: border-box!important;
// `;

// const CollapseContainer = styled.div`
//   text-align: center;
// `;

const Button = styled.div`
  padding: 30px 90px;
  background: linear-gradient(90deg, #ed7f19 0%, #f5b205 100%);
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  margin-top: 32px;
  cursor: pointer;
  @media (max-width: 500px) {
    padding: 14px 72px;
    font-size: 18px;
    line-height: 24px;
  }
`;

const SubmitContainer = styled.div`
  text-align: center;
  margin: 20px;
  span {
    color: #1e89d7;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
  }
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  line-height: 14px;
  padding: 8px 0;
  color: #e74646;
`;

const Typing = keyframes`
0% {
  box-shadow: 9984px 0 0 0 #FFF9E4, 9999px 0 0 0 #FFF9E4, 10014px 0 0 0 #FFF9E4;
}
16.667% {
  box-shadow: 9984px -10px 0 0 #FFF9E4, 9999px 0 0 0 #FFF9E4, 10014px 0 0 0 #FFF9E4;
}
33.333% {
  box-shadow: 9984px 0 0 0 #FFF9E4, 9999px 0 0 0 #FFF9E4, 10014px 0 0 0 #FFF9E4;
}
50% {
  box-shadow: 9984px 0 0 0 #FFF9E4, 9999px -10px 0 0 #FFF9E4, 10014px 0 0 0 #FFF9E4;
}
66.667% {
  box-shadow: 9984px 0 0 0 #FFF9E4, 9999px 0 0 0 #FFF9E4, 10014px 0 0 0 #FFF9E4;
}
83.333% {
  box-shadow: 9984px 0 0 0 #FFF9E4, 9999px 0 0 0 #FFF9E4, 10014px -10px 0 0 #FFF9E4;
}
100% {
  box-shadow: 9984px 0 0 0 #FFF9E4, 9999px 0 0 0 #FFF9E4, 10014px 0 0 0 #FFF9E4;
}
`;

const Circular = styled.div`
  position: relative;
  margin: 0 auto;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff9e4;
  color: #fff9e4;
  box-shadow: 9984px 0 0 0 #fff9e4, 9999px 0 0 0 #fff9e4, 10014px 0 0 0 #fff9e4;
  animation: ${Typing} 1.5s infinite linear;
`;

const SentText = styled.div`
  font-size: 14px;
  line-height: 14px;
  padding: 8px 0;
  color: #1a1007;
`;

const StepWrapper = styled.div`
  display: flex;
  background: #dff2fc;
  height: 148px;
`;

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 537px;
`;

const StepBox = styled.div`
  display: grid;
  place-items: center;
  width: 148px;
  height: 148px;
  position: relative;
  span {
    margin-top: 16px;
    color: #1e89d7;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-wrap;
    text-align: center;
  }
  @media (max-width: 500px) {
    height: 140px;
    width: 120px;
    span {
      font-size: 13px;
      line-height: 21px;
    }
  }
  @media (max-width: 500px) {
    height: 100px;
    width: 100px;
  }
  @media (max-width: 350px) {
    height: 80px;
    width: 80px;
    span {
      font-size: 11px;
      line-height: 18px;
    }
  }
`;

const Icon = styled.img`
  height: 48px;
  @media (max-width: 500px) {
    height: 32px;
  }
  @media (max-width: 350px) {
    height: 28px;
  }
`;

const DXContactForm = ({ modalOpen, forwardedRef }) => {
  const [checked, setChecked] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [companyError, setcompanyError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [deptError, setDeptError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [telError, setTelError] = useState(false);
  const [state, setState] = useState({
    name: '',
    company: '',
    dept: '',
    email: '',
    tel: '',
    inquiry: '',
  });

  const onChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const clearError = () => {
    setcompanyError(false);
    setNameError(false);
    setTelError(false);
    setEmailError(false);
    setDeptError(false);
  };

  const submit = async () => {
    const response = await fetch(
      'https://9s3xu3biv8.execute-api.us-west-2.amazonaws.com/production',
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subject: `DX人材育成事業についてお問い合わせ : ${state.name}様`,
          message: `所属会社 : ${state.company}\n名前 : ${state.name}\n部署 : ${
            state.dept
          }
          \nメールアドレス : ${state.email}\n電話番号 : ${
            state.tel
          }\n相談会の希望 : ${checked ? 'あり' : 'なし'}\n内容 : ${
            state.inquiry
          }`,
        }),
      },
    );

    if (response.ok) {
      navigate('sent');
      setSent(true);
      setSending(false);
    }
    setSending(false);
    setError(true);
  };

  const validate = () => {
    clearError();
    if (sending) return;
    if (state.company === '') {
      setcompanyError(true);
    }

    if (state.name === '') {
      setNameError(true);
    }

    if (state.dept === '') {
      setDeptError(true);
    }

    if (!state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setEmailError(true);
    }

    if (
      state.tel === '' ||
      !state.tel.match(/^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/)
    ) {
      setTelError(true);
    }

    if (
      state.company !== '' &&
      state.name !== '' &&
      state.tel !== '' &&
      state.dept !== '' &&
      state.tel.match(/^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/) &&
      state.email !== '' &&
      state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      setSending(true);
      submit();
    }
  };

  return (
    <Wrapper ref={forwardedRef}>
      <Header>
        <Title>
          資料請求・お問い合わせ<span>はこちらから</span>
        </Title>
      </Header>
      <Container>
        <FormWrapper>
          <StepWrapper>
            <StepContainer>
              <StepBox>
                <Box display="flex" flexDirection="column">
                  <Icon src={StepOneSrc} alt="step1のアイコン" />
                  <span>
                    フォームから{'\n'}
                    お問い合わせ
                  </span>
                </Box>
              </StepBox>
              <img src={BorderArrowSrc} alt="ボーダーのアイコン" />
              <StepBox>
                <Box display="flex" flexDirection="column">
                  <Icon src={StepTwoSrc} alt="step2のアイコン" />
                  <span>
                    メールで{'\n'}
                    資料が届く
                  </span>
                </Box>
              </StepBox>
              <img src={BorderArrowSrc} alt="ボーダーのアイコン" />
              <StepBox>
                <Box display="flex" flexDirection="column">
                  <Icon src={StepThreeSrc} alt="step3のアイコン" />
                  <span>
                    ご希望の方には{'\n'}
                    相談会を開催
                  </span>
                </Box>
              </StepBox>
            </StepContainer>
          </StepWrapper>
          <FormContainer>
            <FormFlexColumnBox>
              <FormLabel htmlFor="name">
                お名前 <span className="required">※必須</span>
              </FormLabel>
              <FormInput
                id="name"
                name="name"
                type="text"
                onChange={e => onChange(e)}
                error={nameError}
              />
              {nameError && (
                <ErrorMessage>必須項目です。入力してください。</ErrorMessage>
              )}
            </FormFlexColumnBox>
            <FormFlexColumnBox>
              <FormLabel htmlFor="company">
                企業名 <span className="required">※必須</span>
              </FormLabel>
              <FormInput
                id="company"
                name="company"
                type="text"
                onChange={e => onChange(e)}
                error={companyError}
              />
              {companyError && (
                <ErrorMessage>必須項目です。入力してください。</ErrorMessage>
              )}
            </FormFlexColumnBox>
            <FormFlexColumnBox>
              <FormLabel htmlFor="dept">
                部署名 <span className="required">※必須</span>
              </FormLabel>
              <FormInput
                id="dept"
                name="dept"
                type="text"
                onChange={e => onChange(e)}
                error={deptError}
              />
              {deptError && (
                <ErrorMessage>必須項目です。入力してください。</ErrorMessage>
              )}
            </FormFlexColumnBox>
            {/* <FormFlexColumnBox>
              <FormLabel htmlFor="position">
                役職名 <span className="required">※必須</span>
              </FormLabel>
              <FormInput
                id="position"
                name="position"
                type="text"
                onChange={e => onChange(e)}
                error={error.position}
              />
            </FormFlexColumnBox> */}
            <FormFlexColumnBox>
              <FormLabel htmlFor="email">
                メールアドレス <span className="required">※必須</span>
              </FormLabel>
              <FormInput
                id="email"
                name="email"
                type="text"
                onChange={e => onChange(e)}
                error={emailError}
              />
              {emailError && (
                <ErrorMessage>メールアドレスを入力してください。</ErrorMessage>
              )}
            </FormFlexColumnBox>
            <FormFlexColumnBox>
              <FormLabel htmlFor="tel">
                電話番号 <span className="required">※必須</span>
              </FormLabel>
              <FormInput
                id="tel"
                name="tel"
                type="text"
                onChange={e => onChange(e)}
                error={telError}
              />
              {telError && (
                <ErrorMessage>正しい電話番号を入力してください。</ErrorMessage>
              )}
            </FormFlexColumnBox>
            <FormFlexColumnBox>
              <FormLabel htmlFor="inquiry">
                お問い合わせ・ご質問 <span className="optional">※任意</span>
              </FormLabel>
              <FormTextArea
                onChange={e => onChange(e)}
                id="inquiry"
                name="inquiry"
              />
            </FormFlexColumnBox>

            <FormCenterBox>
              <FormCheckBox
                type="checkbox"
                id="check"
                name="check"
                onChange={() => setChecked(!checked)}
              />
              <FormLabel htmlFor="check">
                相談会の開催を希望する（オンライン/対面）
                <span className="optional">※任意</span>
              </FormLabel>
            </FormCenterBox>
            {/* Not Necessary on the first version */}

            {/* <Collapse in={checked}>
              <CollapseContainer>
                <Box mt={5} mb={3}>
                  相談会開催ご希望の日程 <span className="optional">※任意</span>
                </Box>
                <FormDateContainer>
                  <DateRowContainer>
                    <DateLabel className="label">第1希望</DateLabel>

                    <DateFlexRow>
                      <DateContainer>
                        <DateInput type="text" />月
                        <DateInput type="text" />日
                      </DateContainer>
                      <NoteLabel>
                        <div className="note">備考（ご希望時間など）</div>
                        <NoteInput
                          type="text"
                          placeholder="12:00以降希望です"
                        />
                      </NoteLabel>
                    </DateFlexRow>
                  </DateRowContainer>

                  <DateRowContainer>
                    <div>
                      <DateLabel>第2希望</DateLabel>
                    </div>
                    <DateFlexRow>
                      <DateContainer>
                        <DateInput type="text" />月
                        <DateInput type="text" />日
                      </DateContainer>
                      <NoteLabel>
                        <div className="note hidden">
                          備考（ご希望時間など）
                        </div>
                        <NoteInput type="text" />
                      </NoteLabel>
                    </DateFlexRow>
                  </DateRowContainer>
                  <DateRowContainer>
                    <div>
                      <DateLabel>第3希望</DateLabel>
                    </div>
                    <DateFlexRow>
                      <DateContainer>
                        <DateInput type="text" />月
                        <DateInput type="text" />日
                      </DateContainer>
                      <NoteLabel>
                        <div className="note hidden">
                          備考（ご希望時間など）
                        </div>
                        <NoteInput type="text" />
                      </NoteLabel>
                    </DateFlexRow>
                  </DateRowContainer>
                </FormDateContainer>
              </CollapseContainer>
            </Collapse> */}
            <SubmitContainer>
              <div>
                <span aria-hidden="true" onClick={() => modalOpen()}>
                  プライバシーポリシー
                </span>
                に同意の上お問い合わせください。
              </div>
              <Button onClick={() => validate()}>
                {sending ? <Circular /> : '資料請求・お問い合わせ'}
              </Button>
              {error && (
                <SentText>
                  送信がうまくいきませんでした。時間をおいて再度お試しください。
                </SentText>
              )}
              {sent && (
                <SentText>
                  送信されました。担当者よりご連絡いたします。
                </SentText>
              )}
            </SubmitContainer>
          </FormContainer>
        </FormWrapper>
      </Container>
    </Wrapper>
  );
};

export default DXContactForm;
