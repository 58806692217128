import React, { useState, useEffect } from 'react';
import OkanoInterviews from './okano-interviews';
import OkanoCEOInterview from './okano-ceo-interview';
import OkanoRegnioDescription from './okano-regnio-description';
import OkanoTeamDescription from './okano-team-description';

const DXCompanyExample = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <>
      <OkanoCEOInterview width={width} />
      <OkanoRegnioDescription />
      <OkanoInterviews width={width} />
      <OkanoTeamDescription />
    </>
  );
};

export default DXCompanyExample;
