import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import RegnioTeamFluidImages from '../../../images/gatsby-image/regnio-team-page/fluid-images';
import { ProjectTitle } from '../constants';
import ArrowSrc from '../../../images/svg/lp-big-arrow.svg';

const Wrapper = styled.div`
  max-width: 1135px;
  margin: 80px auto;
  @media (max-width: 850px) {
    margin: 40px auto;
  }
  @media (max-width: 580px) {
    margin: 26px auto;
  }
`;

const Image = styled(Img)`
  padding: 10px;
  width: 330px;
  max-width: 350px;
  @media (max-width: 850px) {
    width: 220px;
    max-width: 220px;
  }
  @media (max-width: 580px) {
    width: 110px;
    max-width: 110px;
  }
`;

const ArrowImg = styled.img`
  @media (max-width: 850px) {
    width: 50px;
    max-width: 60px;
  }
  @media (max-width: 350px) {
    width: 30px;
  }
`;

const Header = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-weight: normal;
  font-size: 34px;
  line-height: 34px;
  height: 210px;
  color: #52463c;
  .blue {
    display: flex;
    font-weight: bold;
    font-size: 48px;
    line-height: 48px;
    color: #0007ae;
    span {
      font-size: 32px;
    }
  }
  @media (max-width: 850px) {
    max-width: 90%;
    .blue {
      font-weight: bold;
      font-size: 34px;
      line-height: 36px;
      span {
        font-size: 28px;
      }
    }
  }
  @media (max-width: 704px) {
    .blue {
      flex-direction: column;
    }
  }
  @media (max-width: 580px) {
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    span {
      font-size: 26px;
      line-height: 36px;
    }
    .blue {
      white-space: pre-wrap;
      text-align: center;
      margin: 20px 0;
      div {
        margin-bottom: 12px;
      }
      span {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  @media (max-width: 350px) {
    font-size: 18px;
    line-height: 20px;
    span {
      font-size: 24px;
      line-height: 32px;
    }
    .blue {
      font-size: 24px;
      div {
        margin-bottom: 5px;
      }
      span {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 66px 0;
  @media (max-width: 580px) {
    margin: 30px 0;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .blue {
    font-weight: 500;
    font-size: 28px;
    line-height: 48px;
    color: #0007ae;
  }
  @media (max-width: 580px) {
    .blue {
      font-size: 22px;
    }
  }
`;

const OkanoTeamDescription = () => {
  return (
    <Wrapper>
      <Header>
        <div>
          <span>{ProjectTitle}</span>は
        </div>
        <div>社員を株式会社Regnioに派遣して</div>
        <div className="blue">
          <div>
            社内<span>に</span>
          </div>
          <div>
            ITエンジニアチーム<span>を</span>
          </div>
          <div>
            組成
            <span>できます</span>
          </div>
        </div>
      </Header>
      <Container>
        <FlexColumn>
          <Image fluid={RegnioTeamFluidImages('illustThinking')} />
          <div className="blue">BEFORE</div>
        </FlexColumn>
        <FlexColumn>
          <ArrowImg src={ArrowSrc} alt="矢印" />
        </FlexColumn>
        <FlexColumn>
          <Image fluid={RegnioTeamFluidImages('illustGroupCheering')} />
          <div className="blue">AFTER</div>
        </FlexColumn>
      </Container>
    </Wrapper>
  );
};

export default OkanoTeamDescription;
