import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { throttle } from 'lodash';
import DXContactForm from './dx-contact-form';
import DXExamples from './dx-examples';
import DXFeatures from './dx-features';
import DXFooter from './dx-footer';
import DXHeader from './dx-header';
import DXHero from './dx-hero';
import DXCEOProfile from './dx-ceo-profile';
import DXCompanyExample from './dx-company-example';
import DXSchedule from './dx-schedule';
import SEOHeader from '../common-components/templates/page-root-v2/seo-v2';
import './normalize.css';
import DXModal from './dx-modal';
import useJudgeServerSideRendering from '../../utils/judge-ssr';
import '../../styles/foundation-fix.css';

const Wrapper = styled.div`
  overflow-x: hidden;
`;

const DXHRDevComponent = () => {
  const judgeSSR = useJudgeServerSideRendering();
  const ref = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [state, setState] = useState({
    position: 0,
    show: true,
  });
  const handleScroll = () => {
    setState(prev => {
      return {
        position: document.body.getBoundingClientRect().top,
        show: document.body.getBoundingClientRect().top > prev.position,
      };
    });
  };
  const handleScrollThrottled = throttle(handleScroll, 150);
  useEffect(() => {
    window.addEventListener('scroll', handleScrollThrottled);
    return () => window.removeEventListener('scroll', handleScrollThrottled);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const scrollToForm = e => {
    e.preventDefault();
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  if (!judgeSSR) return <>ローディング中</>;

  return (
    <div>
      <Helmet>
        <html className="fix" lang="ja" />
      </Helmet>
      <Wrapper>
        <SEOHeader pageTitle="DX技術者育成プログラム" />
        <DXHeader
          offset={state.position}
          show={state.show}
          scrollToForm={scrollToForm}
        />
        <DXHero scrollToForm={scrollToForm} />
        <DXCompanyExample />
        <DXSchedule />
        <DXFeatures />
        <DXCEOProfile />
        <DXExamples />
        <DXContactForm modalOpen={handleOpen} forwardedRef={ref} />
        <DXFooter modalOpen={handleOpen} />
        <DXModal open={openModal} handleClose={handleClose} />
      </Wrapper>
    </div>
  );
};

export default DXHRDevComponent;
