import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import RegnioTeamFluidImages from '../../../images/gatsby-image/regnio-team-page/fluid-images';
import LeftSlashSrc from '../../../images/svg/lp-left-slash.svg';
import RightSlashSrc from '../../../images/svg/lp-right-slash.svg';

const Wrapper = styled.div`
  margin-bottom: 80px;
`;

const Header = styled(Box)`
  position: relative;
  background: #242899;
  height: 190px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 24px;
  }
  @media (max-width: 500px) {
    height: 111px;
  }
`;

const HeaderLeftBox = styled.div`
  margin-right: 54px;
  @media (max-width: 1024px) {
    margin-right: 22px;
  }
`;

const HeaderSubHeading = styled.div`
  font-weight: 300;
  font-size: 40px;
  line-height: 36px;
  text-align: ${props => (props.left ? 'left' : 'right')};
  margin-right: ${props => (props.left ? '50px' : '0')};
  @media (max-width: 1024px) {
    font-size: 32px;
  }
  @media (max-width: 700px) {
    font-size: 32px;
  }
`;

const HeaderBold = styled.div`
  font-weight: 500;
  font-size: 48px;
  @media (max-width: 1024px) {
    font-size: 36px;
  }
`;

const SmallSpan = styled.span`
  font-size: 18px !important;
  line-height: 24px;
  padding: 8px 0;
`;

const Body = styled.div`
  display: flex;
  max-width: 1135px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const BodyLeft = styled.div`
  width: 50vw;
  z-index: -1;
  @media (max-width: 850px) {
    width: 100%;
  }
`;

const BodyRight = styled.div`
  width: 50vw;
  margin-top: 32px;
  @media (max-width: 850px) {
    width: 100%;
    position: relative;
  }
`;

const BodyHeading = styled.div`
  color: #0007ae;
  margin-left: -72px;
  margin-bottom: 46px;
  text-align: left;
  div {
    background: #fff;
    padding: 2px 6px 10px 11px;
    margin: 2px 0;
  }
  .heading1 {
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
    span {
      font-size: 48px;
    }
  }
  .heading2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 36px;
  }
  .heading-span {
    padding: 11px 10px 10px 11px;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
  }

  @media (max-width: 1070px) {
    .heading1 {
      font-size: 24px;
      span {
        font-size: 36px;
      }
    }
    .heading2 {
      font-size: 26px;
    }
  }

  @media (max-width: 850px) {
    position: absolute;
    top: -420px;
    margin-left: 0;
  }

  @media (max-width: 550px) {
    top: -300px;
    font-size: 18px;
    line-height: 24px;
    box-sizing: border-box !important;
    div {
      color: #fff;
      background: #0007ae;
      width: fit-content;
      padding: 3px 6px 5px 8px;
      margin: 2px 8px;
    }

    .heading-span {
      font-size: 18px;
      line-height: 24px;
    }

    .heading1 {
      font-size: 20px;
      span {
        font-size: 28px;
        line-height: 30px;
      }
    }
    .heading2 {
      span {
        font-size: 20px;
      }
    }
  }
  @media (max-width: 320px) {
    font-size: 18px;
    line-height: 24px;

    .heading-span {
      font-size: 16px;
      line-height: 18px;
    }

    .heading1 {
      font-size: 16px;
      span {
        font-size: 24px;
        line-height: 26px;
      }
    }
    .heading2 {
      span {
        font-size: 18px;
      }
    }
  }
`;

const TextContainer = styled.div`
  color: #1a1007;
  font-weight: normal;
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 16px;
  padding: 0 42px;
  span {
    font-size: 16px;
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    padding: 0 21px;
  }
`;

const BlueTextContainer = styled.div`
  color: #0007ae;
  font-weight: 300;
  font-size: 28px;
  line-height: 28px;
  padding: 24px 42px;
  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 24px;
    padding: 24px 21px;
  }
`;

const OkanoContainer = styled.div`
  margin: 45px auto;
  .okano {
    font-weight: 300;
    font-size: 34px;
    line-height: 34px;
    margin: 25px 0 20px 0;
  }
  .name {
    font-size: 24px;
    font-weight: 300;
    span {
      margin-left: 23px;
      font-weight: 300;
      font-size: 36px;
      line-height: 36px;
    }
  }
  @media (max-width: 1024px) {
    .okano {
      font-size: 22px;
      margin: 12px 0;
    }
    .name {
      font-size: 18px;
      span {
        font-size: 24px;
      }
    }
  }
  @media (max-width: 850px) {
    text-align: right;
    margin-right: 50px;
  }
  @media (max-width: 550px) {
    font-weight: 300;
    margin: 120px 16px 0 16px;
    color: #0007ae;
    .okano {
      font-size: 24px;
      line-height: 24px;
      text-align: left;
    }
    .name {
      font-size: 16px;
      line-height: 16px;
      span {
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
`;

const LightText = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  @media (max-width: 800px) {
    white-space: pre-wrap;
  }
  @media (max-width: 550px) {
    color: #0007ae;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    margin-bottom: 14px;
  }
`;

const Image = styled(Img)`
  width: 100%;
`;

const SPHeaderContainer = styled.div`
  color: #0007ae;
  margin: 0 auto;
`;

const SPHeaderHeading = styled.div`
  color: #fff;
  font-weight: 500;
  font-size: 48px;
  line-height: 46px;
  display: flex;
  align-items: baseline;
  padding-bottom: 8px;
  span {
    font-size: 36px;
  }
  @media (max-width: 500px) {
    font-size: 32px;
    line-height: 32px;
    span {
      font-size: 18px;
    }
  }
  @media (max-width: 320px) {
    font-size: 26px;
    line-height: 24px;
    span {
      font-size: 14px;
    }
  }
`;

const SPHeaderTopContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  padding: 20px 0;
  img {
    height: 60px;
    margin: 0 8px;
  }
`;

const SPHeaderLeft = styled.div`
  font-weight: 300;
  font-size: 22px;
  line-height: 24px;
  span {
    font-size: 14px;
    line-height: 24px;
  }
  @media (min-width: 600px) {
    font-size: 42px;
    line-height: 40px;
    span {
      font-size: 28px;
    }
  }
  @media (max-width: 320px) {
    font-size: 16px;
    line-height: 14px;
    span {
      font-size: 10px;
    }
  }
`;

const SPHeaderRight = styled.div`
  font-weight: 500;
  font-size: 26px;
  line-height: 26px;
  span {
    font-size: 16px;
    line-height: 26px;
  }
  @media (min-width: 600px) {
    font-size: 42px;
    line-height: 40px;
    span {
      font-size: 28px;
    }
  }
  @media (max-width: 320px) {
    font-size: 20px;
    line-height: 18px;
    span {
      font-size: 14px;
    }
  }
`;

const OkanoCEOInterview = ({ width }) => {
  const SPHeader = () => {
    return (
      <>
        <SPHeaderContainer>
          <SPHeaderTopContainer>
            <img src={LeftSlashSrc} alt="アイコン" />
            <SPHeaderLeft>
              <div>
                老舗企業<span>と</span>
              </div>
              <div>
                IT企業<span>が</span>
              </div>
            </SPHeaderLeft>
            <SPHeaderRight>
              <div>
                DX<span>に</span>二人三脚<span>で</span>
              </div>
              <div>
                <span>取り組み続けて生まれた</span>
              </div>
            </SPHeaderRight>
            <img src={RightSlashSrc} alt="アイコン" />
          </SPHeaderTopContainer>
        </SPHeaderContainer>
        <Header
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          px={3}
        >
          <SPHeaderContainer>
            <SPHeaderHeading>
              現場<span>での</span>
            </SPHeaderHeading>
            <SPHeaderHeading>IT人材育成プログラム</SPHeaderHeading>
          </SPHeaderContainer>
        </Header>
      </>
    );
  };

  return (
    <Wrapper>
      {width < 710 ? (
        <SPHeader />
      ) : (
        <Header>
          <HeaderLeftBox>
            <HeaderSubHeading left>
              老舗企業<span>と</span>
            </HeaderSubHeading>
            <HeaderSubHeading>
              IT企業<span>が</span>
            </HeaderSubHeading>
          </HeaderLeftBox>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <HeaderBold>
              DX<span>に</span>二人三脚<span>で</span>
            </HeaderBold>
            <SmallSpan>取り組み続けて生まれた</SmallSpan>
            <HeaderBold>
              現場<span>での</span>IT人材育成プログラム
            </HeaderBold>
          </Box>
        </Header>
      )}
      <Body>
        <BodyLeft>
          <div>
            <Image
              fluid={RegnioTeamFluidImages('okanoCeo')}
              imgStyle={{ objectFit: 'contain' }}
              alt="岡野社長"
            />
          </div>
          <OkanoContainer>
            <LightText>
              未来を見据えて{'\n'}DXと組織風土の改革に取り組む老舗企業
            </LightText>
            <div className="okano">岡野バルブ製造株式会社</div>
            <div className="name">
              代表取締役社長<span>岡野 武治</span>
            </div>
          </OkanoContainer>
        </BodyLeft>
        <BodyRight>
          <BodyHeading>
            <div className="heading-span">将来は</div>
            <div className="heading1">
              <span>50</span>人の<span>ITエンジニアチーム</span>を
            </div>
            <div className="heading2">
              社内<span>に作りたいと思っています。</span>
            </div>
          </BodyHeading>
          <div>
            <TextContainer>
              これからの時代、やりたいことをやる為には
              <span>絶対にテクノロジーの力が必要です。</span>
            </TextContainer>
            <TextContainer>
              <span>スピーディーにテクノロジーを活用できる体制</span>
              を整えるために、社内にITエンジニアがいて欲しいと思うようになりました。
            </TextContainer>
            <BlueTextContainer>深い業務理解×最新のIT技術</BlueTextContainer>
            <TextContainer>
              Regnioの中野さんたちとは、バルブメンテナンスのシステム開発を3年間、二人三脚でやってきたので、
              <span>ITエンジニアの育成もお願いしよう！</span>と思いました。
            </TextContainer>
            <TextContainer>
              DXを推進し、企業として強くなるためには、
              <span>
                社内の人材や組織風土に、「新しいもの」を素直に取り入れ、自分たちのものにしていくという柔軟さ
              </span>
              そのものが必要であると考えます。
            </TextContainer>
            <TextContainer>
              システム制作は全て外注する、という姿勢でDXに取り組むと、
              <span>
                深い業務理解を必要とするため、コミュニケーションコストがどうしてもかさんでしまいます。
              </span>
              数年後を見据えて今から人材教育に力を入れることが、必ず将来活きてくると確信しています。
            </TextContainer>
          </div>
        </BodyRight>
      </Body>
    </Wrapper>
  );
};

export default OkanoCEOInterview;
