import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import RegnioTeamFluidImages from '../../../images/gatsby-image/regnio-team-page/fluid-images';

const StepTwoWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 80px;
  @media (max-width: 1134px) {
    justify-content: center;
    flex-direction: row-reverse;
    align-items: end;
    margin: 40px auto;
  }

  @media (max-width: 750px) {
    display: block;
    background: #fff;
    padding: 40px 0;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 44px;
  line-height: 64px;
  color: #0007ae;
  span {
    margin: 0 12px;
  }
  .small {
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    color: #0007ae;
  }
  .step {
    font-weight: 600;
    font-size: 48px;
    line-height: 65px;
    color: #52463c;
    position: relative;
    ::before {
      content: 'STEP';
      position: absolute;
      top: 0;
      left: 20%;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
    }
  }
  @media (max-width: 850px) {
    span {
      margin: 0;
      margin-right: 8px;
    }
    .small {
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      color: #0007ae;
    }
  }
  @media (max-width: 555px) {
    font-size: 30px;
    line-height: 32px;
    color: #0007ae;
    span {
      margin: 0;
    }
    .small {
      font-size: 14px;
      line-height: 20px;
    }
    .step {
      font-size: 24px;
      line-height: 24px;
      margin-right: 8px;
      ::before {
        position: absolute;
        top: -8px;
        left: 0%;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
      }
    }
  }
  @media (max-width: 330px) {
    .small {
      white-space: pre;
      display: block;
      text-align: right;
    }
  }
`;

const ImageContainer = styled(Box)`
  width: 178px;
`;

const Image = styled(Img)`
  width: 178px;
  @media (max-width: 750px) {
    width: 110px;
  }
`;

const TextContents = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: #52463c;
  white-space: pre-wrap;
  margin: 12px 0;
  @media (max-width: 750px) {
    padding: 0 8px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px;
`;

const SPContents = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 24px auto;
  @media (max-width: 750px) {
    width: auto;
  }
`;

const StepTwo = ({ width }) => {
  const SPContainer = () => {
    return (
      <StepTwoWrapper>
        <Container>
          <Title>
            <span className="step">02</span>
            <span>人材のお預かり</span>
            <span className="small">（出向の開始）</span>
          </Title>
          <SPContents>
            <TextContents>
              育成したい人材を、弊社に出向するよう手配していただきます。{'\n'}
              ※出向先は株式会社Regnio（福岡県福岡市）になります。
            </TextContents>
            <ImageContainer>
              <Image fluid={RegnioTeamFluidImages('illustGreeting')} />
            </ImageContainer>
          </SPContents>
        </Container>
      </StepTwoWrapper>
    );
  };

  return (
    <div>
      {width < 751 ? (
        <SPContainer />
      ) : (
        <StepTwoWrapper>
          <ImageContainer mr={4}>
            <Image fluid={RegnioTeamFluidImages('illustGreeting')} />
          </ImageContainer>
          <Container>
            <Title>
              <span className="step">02</span>
              <span>人材のお預かり</span>
              <span className="small">{'\n'}（出向の開始）</span>
            </Title>
            <TextContents>
              育成したい人材を、弊社に出向するよう手配していただきます。{'\n'}
              ※出向先は株式会社Regnio（福岡県福岡市）になります。
            </TextContents>
          </Container>
        </StepTwoWrapper>
      )}
    </div>
  );
};

export default StepTwo;
