import React, { useState, useEffect } from 'react';
import DXFeaturePoints from './dx-feature-points';
import DXFeatureGraphs from './dx-feature-graph';
import DXFeatutreSkills from './dx-feature-skills';

const DXFeatures = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);
  return (
    <>
      <DXFeaturePoints />
      <DXFeatureGraphs />
      <DXFeatutreSkills width={width} />
    </>
  );
};

export default DXFeatures;
