import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Box } from '@material-ui/core';
import RegnioTeamFluidImages from '../../../images/gatsby-image/regnio-team-page/fluid-images';
import ComputerIconSrc from '../../../images/svg/lp-computer.svg';
import BookIconSrc from '../../../images/svg/lp-book.svg';
import TermIconSrc from '../../../images/svg/lp-term.svg';
import BusinessIconSrc from '../../../images/svg/lp-business.svg';
import MaruIconSrc from '../../../images/svg/lp-maru.svg';
import SankakuIconSrc from '../../../images/svg/lp-sankaku.svg';
import HyphenIconSrc from '../../../images/svg/lp-hyphen.svg';
import { ProjectTitle } from '../constants';

const Wrapper = styled.div`
  background: rgba(255, 233, 153, 0.15);
  padding-bottom: 80px;
`;

const Header = styled.div`
  background: linear-gradient(90deg, #1926a0 0%, #481b75 100%);
  height: 122px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ::after {
    position: absolute;
    top: 99%;
    content: '';
    width: 0;
    height: 0;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-top: 50px solid #30218b;
  }
  @media (max-width: 750px) {
    flex-direction: column;
  }
  @media (max-width: 700px) {
    height: 168px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 750px) {
    flex-direction: column;
    margin-bottom: 24px;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin-right: 12px;
  @media (max-width: 750px) {
    flex-direction: row;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 51px;

  .small {
    margin-left: 6px;
    font-size: 24px;
  }
  @media (max-width: 700px) {
    white-space: pre-wrap;
    text-align: right;
  }
  @media (max-width: 500px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

const Image = styled(Img)`
  width: 220px;
  height: 219px;
`;

const TextContents = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: #52463c;
  white-space: pre-wrap;
  max-width: 500px;
  margin-left: 53px;
  @media (max-width: 750px) {
    max-width: 100%;
    margin: 0 16px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 29px;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
`;

const Container = styled.div`
  max-width: 1135px;
  margin: 0 auto;
  padding-top: 80px;
  @media (max-width: 1134px) {
    margin: 0 16px;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 6px;
`;

const ColoredGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border: 6px solid #0007ae;
  gap: 6px;
  border-color: ${props =>
    props.gradient
      ? 'linear-gradient(90deg, #1926a0 0%, #481b75 100%)'
      : '#828282'};
  border-radius: 8px;
  color: #fff;
  margin: 6px 0;
`;

const GridBox = styled.div`
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  background: ${props => (props.blue ? ' #EEF6FA' : '#fff')};
  color: ${props => (props.blue ? ' #1E89D7' : '#52463C')};
  font-weight: ${props => (props.blue ? ' bold' : 'normal')};
`;

const GridBoxBlueText = styled.div`
  @media (max-width: 750px) {
    font-size: 11px;
    line-height: 14px;
    text-align: center;
  }
  @media (max-width: 500px) {
    white-space: pre-wrap;
  }
`;

const ColoredGridBox = styled.div`
  background: ${props =>
    props.gradient
      ? 'linear-gradient(90deg, #1926a0 0%, #481b75 100%)'
      : '#828282'};
  height: 140px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 43px;
  white-space: pre-wrap;
  display: grid;
  place-items: center;
  span {
    display: contents;
  }
  @media (max-width: 1134px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media (max-width: 710px) {
    span {
      display: none;
    }
  }
  @media (max-width: 500px) {
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
  }
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
  margin-top: 12px;
  @media (max-width: 950px) {
    width: 36px;
    height: 36px;
  }
  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
  }
`;

const GridText = styled.div`
  margin-top: 15px;
  white-space: pre-wrap;
  text-align: center;
  @media (max-width: 950px) {
    display: none;
  }
`;

const GridLText = styled.div`
  font-weight: normal;
  font-size: 22px;
  line-height: 21px;
  text-align: center;
  color: ${props => (props.active ? '#52463C' : '#BDBDBD')};
  @media (max-width: 1134px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media (max-width: 750px) {
    font-size: 14px;
    line-height: 12px;
    padding: 5px;
  }
  @media (max-width: 514px) {
    white-space: pre-wrap;
    line-height: 16px;
  }
  @media (max-width: 450px) {
    font-size: 10px;
    line-height: 16px;
  }
`;

const HiddenContainer = styled.div`
  display: none;
  margin: 0 16px;
  .hidden-icon {
    margin-top: 0;
  }
  .hidden-text {
    font-size: 14px;
    line-height: 21px;
    margin-left: 53px;
  }
  @media (max-width: 950px) {
    display: block;
  }
`;

const HiddenBlueText = styled.div`
  color: #1e89d7;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  margin-left: 16px;
`;

const DXFeatureGraphs = () => {
  const BottomContainer = () => {
    return (
      <div>
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          mt={2}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <Icon
              className="hidden-icon"
              src={ComputerIconSrc}
              alt="パソコンアイコン"
            />
            <HiddenBlueText>現場での実務経験</HiddenBlueText>
          </Box>
          <div className="hidden-text">
            現役エンジニアと一緒に現場で実務を行います
          </div>
        </Box>
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <Icon
              className="hidden-icon"
              src={BookIconSrc}
              alt="勉強のアイコン"
            />
            <HiddenBlueText>体系的IT知識</HiddenBlueText>
          </Box>
          <div className="hidden-text">
            学習しながら自走できるようになります
          </div>
        </Box>
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <Icon
              className="hidden-icon"
              src={BusinessIconSrc}
              alt="ビジネススキルのアイコン"
            />
            <HiddenBlueText>ビジネススキル</HiddenBlueText>
          </Box>
          <div className="hidden-text">
            資料作成・プレゼン・コミュニケーション能力等
          </div>
        </Box>
      </div>
    );
  };

  return (
    <Wrapper>
      <Header>
        <FlexColumn>
          <div>ITスキルの習得</div>
          <div>だけではなく</div>
        </FlexColumn>
        <Title>
          座学<span className="small">では</span>得られない！{'\n'}
          現場<span className="small">での</span>経験
        </Title>
      </Header>
      <Container>
        <TitleContainer>
          <Image
            fluid={RegnioTeamFluidImages('illustGroupCheeringWithoutBg')}
            imgStyle={{ objectFit: 'contain' }}
          />
          <TextContents>
            {ProjectTitle}は、エンジニアとしての知識を得るだけでなく、
            他サービスでは得られない、ビジネススキルや経験が、
            ベテランエンジニアと一緒に仕事をする中で身につきます。
          </TextContents>
        </TitleContainer>
        <GridContainer>
          <div />
          <GridBox blue>
            <GridBoxBlueText>現場での{'\n'}実務経験</GridBoxBlueText>
            <div>
              <Icon src={ComputerIconSrc} alt="パソコンアイコン" />
            </div>
          </GridBox>
          <GridBox blue>
            <GridBoxBlueText>体系的{'\n'}IT知識</GridBoxBlueText>
            <div>
              <Icon src={BookIconSrc} alt="本のアイコン" />
            </div>
          </GridBox>
          <GridBox blue>
            <GridBoxBlueText>ビジネス{'\n'}スキル</GridBoxBlueText>
            <div>
              <Icon src={BusinessIconSrc} alt="ビジネスアイコン" />
            </div>
          </GridBox>
          <GridBox blue>
            <GridBoxBlueText>期間</GridBoxBlueText>
            <div>
              <Icon src={TermIconSrc} alt="期間のアイコン" />
            </div>
          </GridBox>
        </GridContainer>
        <ColoredGridContainer gradient>
          <ColoredGridBox gradient>
            DX技術者{'\n'}
            育成 <span>プログラム</span>
          </ColoredGridBox>
          <GridBox>
            <div>
              <Icon src={MaruIconSrc} alt="丸のアイコン" />
            </div>
            <GridText>
              現役エンジニアと一緒に{'\n'}現場で実務を行います
            </GridText>
          </GridBox>
          <GridBox>
            <div>
              <Icon src={MaruIconSrc} alt="丸のアイコン" />
            </div>

            <GridText>学習しながら{'\n'}自走できるようになります</GridText>
          </GridBox>
          <GridBox>
            <div>
              <Icon src={MaruIconSrc} alt="丸のアイコン" />
            </div>
            <GridText>
              資料作成・プレゼン・{'\n'}コミュニケーション能力等
            </GridText>
          </GridBox>
          <GridBox>
            <GridLText active>半年〜{'\n'}1年</GridLText>
          </GridBox>
        </ColoredGridContainer>
        <ColoredGridContainer>
          <ColoredGridBox>セミナー</ColoredGridBox>
          <GridBox>
            <GridLText>
              <Icon src={HyphenIconSrc} alt="-のアイコン" />
            </GridLText>
          </GridBox>
          <GridBox>
            <div>
              <Icon src={MaruIconSrc} alt="丸のアイコン" />
            </div>
          </GridBox>
          <GridBox>
            <div>
              <Icon src={SankakuIconSrc} alt="丸のアイコン" />
            </div>
          </GridBox>
          <GridBox>
            <GridLText active>1日〜</GridLText>
          </GridBox>
        </ColoredGridContainer>
        <ColoredGridContainer>
          <ColoredGridBox>スクール</ColoredGridBox>
          <GridBox>
            <div>
              <Icon src={HyphenIconSrc} alt="-のアイコン" />
            </div>
          </GridBox>
          <GridBox>
            <div>
              <Icon src={MaruIconSrc} alt="丸のアイコン" />
            </div>
          </GridBox>
          <GridBox>
            <div>
              <Icon src={SankakuIconSrc} alt="丸のアイコン" />
            </div>
          </GridBox>
          <GridBox>
            <GridLText active>3日〜{'\n'}半年以上</GridLText>
          </GridBox>
        </ColoredGridContainer>
        <ColoredGridContainer>
          <ColoredGridBox>独学</ColoredGridBox>
          <GridBox>
            <div>
              <Icon src={HyphenIconSrc} alt="-のアイコン" />
            </div>
          </GridBox>
          <GridBox>
            <div>
              <Icon src={MaruIconSrc} alt="丸のアイコン" />
            </div>
          </GridBox>
          <GridBox>
            <div>
              <Icon src={HyphenIconSrc} alt="-のアイコン" />
            </div>
          </GridBox>
          <GridBox>
            <GridLText active>1ヶ月〜{'\n'}1年以上</GridLText>
          </GridBox>
        </ColoredGridContainer>
      </Container>

      <HiddenContainer>
        <BottomContainer />
      </HiddenContainer>
    </Wrapper>
  );
};

export default DXFeatureGraphs;
