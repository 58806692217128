import { useStaticQuery, graphql } from 'gatsby';

const RegnioTeamFluidImages = image => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "regnio-team-lp/hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, maxHeight: 540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      okanoCeo: file(relativePath: { eq: "regnio-team-lp/okano-san.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, maxHeight: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      okuda: file(relativePath: { eq: "regnio-team-lp/okuda.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 642) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mako: file(relativePath: { eq: "regnio-team-lp/mako.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500, maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      illustStudy: file(relativePath: { eq: "regnio-team-lp/illust_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 280, maxHeight: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      illustThinking: file(
        relativePath: { eq: "regnio-team-lp/thinking.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 280, maxHeight: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      illustGroupCheering: file(
        relativePath: { eq: "regnio-team-lp/group_cheering.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 280, maxHeight: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      illustGroupStudy: file(
        relativePath: { eq: "regnio-team-lp/group_study.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 508, maxHeight: 368) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      illustGreeting: file(
        relativePath: { eq: "regnio-team-lp/greeting.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 268, maxHeight: 408) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      illustCoach: file(
        relativePath: { eq: "regnio-team-lp/group_coaching.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 442, maxHeight: 354) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      illustLecture: file(
        relativePath: { eq: "regnio-team-lp/group_lecture.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 684, maxHeight: 842) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      MasaTalk: file(relativePath: { eq: "regnio-team-lp/masa_talking.png" }) {
        childImageSharp {
          fluid(maxWidth: 680, maxHeight: 904) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DataSet: file(relativePath: { eq: "regnio-team-lp/data_set.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 388) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DataSystem: file(relativePath: { eq: "regnio-team-lp/data_system.png" }) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 408) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      PairProgramming: file(
        relativePath: { eq: "regnio-team-lp/pairprograming.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 438, maxHeight: 356) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Hand: file(relativePath: { eq: "regnio-team-lp/hand.png" }) {
        childImageSharp {
          fluid(maxWidth: 146, maxHeight: 219) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      illustGroupCheeringWithoutBg: file(
        relativePath: { eq: "regnio-team-lp/group_cheering_without_bg.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 280, maxHeight: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      masaProfile: file(
        relativePath: { eq: "regnio-team-lp/masa_profile.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, maxHeight: 880) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      skillWeb: file(relativePath: { eq: "regnio-team-lp/skill_web.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 499) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      skillIoT: file(relativePath: { eq: "regnio-team-lp/skill_iot.png" }) {
        childImageSharp {
          fluid(maxWidth: 830, maxHeight: 860) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      skillAI: file(relativePath: { eq: "regnio-team-lp/skill_ai.png" }) {
        childImageSharp {
          fluid(maxWidth: 560, maxHeight: 880) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DXExampleOne: file(
        relativePath: { eq: "regnio-team-lp/dxexample1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1536, maxHeight: 1010) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DXExampleTwo: file(
        relativePath: { eq: "regnio-team-lp/dxexample2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1536, maxHeight: 1010) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DXExampleSmallOne: file(
        relativePath: { eq: "regnio-team-lp/dxexample2_2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640, maxHeight: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DXExampleSmallTwo: file(
        relativePath: { eq: "regnio-team-lp/dxexample2_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640, maxHeight: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BgWave: file(relativePath: { eq: "regnio-team-lp/bg_wave.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return data[image].childImageSharp.fluid;
};

export default RegnioTeamFluidImages;
