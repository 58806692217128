import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Box } from '@material-ui/core';
import RegnioTeamFluidImages from '../../images/gatsby-image/regnio-team-page/fluid-images';

const Wrapper = styled.div`
  background: rgba(0, 124, 194, 0.04);
`;

const Container = styled.div`
  max-width: 1135px;
  margin: 0 auto;
`;

const Header = styled.div`
  color: #1e89d7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0;
  @media (max-width: 1100px) {
    padding: 32px 0;
  }
  @media (max-width: 980px) {
    padding: 32px 0 16px 0;
  }
`;

const HeaderTopText = styled.div`
  font-size: 40px;
  line-height: 40px;
  span {
    font-size: 30px;
    line-height: 30px;
  }
  @media (max-width: 450px) {
    font-size: 24px;
    line-height: 24px;
    span {
      font-size: 18px;
      line-height: 18px;
    }
  }
`;

const HeaderBottomText = styled.div`
  font-size: 58px;
  line-height: 58px;
  span {
    font-size: 68px;
    line-height: 68px;
  }
  @media (max-width: 450px) {
    font-size: 40px;
    line-height: 40px;
    span {
      font-size: 48px;
      line-height: 48px;
    }
  }
`;

const Heading = styled.div`
  white-space: pre-wrap;
  font-weight: bold;
  font-size: 28px;
  line-height: 42px;
  color: #52463c;
  @media (max-width: 980px) {
    color: #1e89d7;
    width: 70%;
    margin: 0 auto;
  }
  @media (max-width: 550px) {
    width: 100%;
  }
  @media (max-width: 450px) {
    font-size: 22px;
    line-height: 33px;
  }
`;

const TextContainer = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 29px;
  margin: 8px 0;
  color: #52463c;
  span {
    font-weight: 700;
  }
  @media (max-width: 980px) {
    width: 70%;
    padding-top: 20px;
    margin: 0 auto;
  }
  @media (max-width: 550px) {
    width: 100%;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  position: relative;
  height: 700px;
  padding: 40px 0;
  @media (max-width: 1100px) {
    padding: 20px 0;
  }
  @media (max-width: 980px) {
    flex-direction: ${props => (props.bottom ? 'column-reverse' : 'column')};
    align-items: center;
    height: auto;
  }
  @media (max-width: 550px) {
    padding-bottom: 0px;
  }
`;

const ImageContainer = styled.div`
  width: 55vw;
  margin-left: ${props => (props.left ? '50%' : '0')};
  position: absolute;
  bottom: ${props => (props.left ? '0' : '')};
  @media (max-width: 980px) {
    position: relative;
    margin-left: 0;
    width: 70%;
  }
  @media (max-width: 550px) {
    width: 100%;
  }
`;

const Image = styled(Img)`
  width: auto;
  height: 528px;
  @media (max-width: 980px) {
    height: auto;
  }
`;

const SecondImage = styled(Img)`
  width: auto;
  height: auto;
`;

const SmallImage = styled(Img)`
  width: 320px;
  @media (max-width: 980px) {
    width: 50%;
    height: 50%;
  }
`;

const FloatingTextRightContainer = styled.div`
  z-index: 5;
  position: absolute;
  padding: 1.5rem 2.5rem;
  background: #ffffff;
  width: 50%;
  height: 528px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: -10px;
  top: 120px;
  @media (max-width: 980px) {
    position: relative;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
  }
  @media (max-width: 550px) {
    padding: 16px;
  }
  @media (max-width: 450px) {
    padding: 16px;
  }
`;

const FloatingTextLeftContainer = styled.div`
  z-index: 5;
  position: absolute;
  padding: 1.5rem 2.5rem;
  background: #ffffff;
  width: 50%;
  height: 528px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 120px;
  left: 0px;
  @media (max-width: 980px) {
    position: relative;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
  }
  @media (max-width: 550px) {
    padding: 16px;
  }
  @media (max-width: 450px) {
    padding: 16px;
  }
`;

const DXExamples = () => {
  return (
    <Wrapper>
      <Header>
        <div>
          <HeaderTopText>
            <span>株式会社</span>Regnio<span>の</span>
          </HeaderTopText>
          <HeaderBottomText>
            <span>DX</span>事例紹介
          </HeaderBottomText>
        </div>
      </Header>
      <Container>
        <FlexContainer>
          <ImageContainer>
            <Image
              fluid={RegnioTeamFluidImages('DXExampleOne')}
              imgStyle={{ objectFit: 'contain' }}
              alt="DX事例1"
            />
          </ImageContainer>
          <FloatingTextRightContainer>
            <Heading>
              老舗機械工作メーカーとの{'\n'}
              ファクトリーオートメーション{'\n'}
              システムの共同開発{'\n'}
            </Heading>
            <TextContainer>
              現場での業務をサポートする、自動制御システムを開発し、
              <span>安全性の向上</span>
              や、<span>後継者不足の解消、</span>現場の<span>負担の軽減</span>
              を目指しています。
            </TextContainer>
            <TextContainer>
              実現の為には、現場で手を動かしている匠自身も言語化が難しい、「熟練の技（無意識のうちに行っている行動/判断）」のデータ化・AIモデルの生成をする必要があります。
            </TextContainer>
            <TextContainer>
              <span>データを取得すること自体が非常に困難</span>
              な現場であっても、ハードウェア技術・システム技術・通信技術・機械学習/データ分析技術を駆使し、未来を支える為のプロダクトを共同開発しています。
            </TextContainer>
          </FloatingTextRightContainer>
        </FlexContainer>
        <FlexContainer bottom>
          <FloatingTextLeftContainer>
            <Heading>
              超高圧バルブの{'\n'}
              メンテナンス・データ管理{'\n'}システムの 開発と運用を{'\n'}
              OJT指導を実施しながら行っています。{'\n'}
            </Heading>
            <TextContainer>
              発電所などで使用する超高圧バルブを製造する老舗企業、岡野バルブ様とのプロジェクト。
            </TextContainer>
            <TextContainer>
              バルブ診断装置とWEBシステムという、
              <span>ハードとソフトが融合した次世代メンテナンスシステム</span>
              を開発しています。
            </TextContainer>
            <TextContainer>
              現在、
              <span>
                実際に2名（+追加で更に2名）、出向という形で弊社に出勤してもらいながら、
              </span>
              日々システムの開発に尽力頂いております。
            </TextContainer>
          </FloatingTextLeftContainer>
          <ImageContainer left>
            <SecondImage
              fluid={RegnioTeamFluidImages('DXExampleTwo')}
              imgStyle={{ objectFit: 'contain' }}
              alt="DX事例2-1"
            />
            <Box display="flex" justifyContent="flex-end">
              <SmallImage
                fluid={RegnioTeamFluidImages('DXExampleSmallOne')}
                imgStyle={{ objectFit: 'contain' }}
                alt="DX事例2-2"
              />
              <SmallImage
                fluid={RegnioTeamFluidImages('DXExampleSmallTwo')}
                imgStyle={{ objectFit: 'contain' }}
                alt="DX事例2-3"
              />
            </Box>
          </ImageContainer>
        </FlexContainer>
      </Container>
    </Wrapper>
  );
};

export default DXExamples;
