export const ProjectTitle = 'DX技術者育成プログラム';

export const interviews = [
  {
    name: '奥田 祐一郎',
    company: '岡野バルブ製造株式会社',
    department: '経営本部VQ事業部',
    image: 'okuda',
    link: 'https://www.regnio.co.jp/contents/114/',
    title: 'おもいを『カタチ』にできる力がつく',
    contents: [
      '私は1年前、非エンジニアとしてのスタートでしたが、今回のプログラムに参加して、独学で学ぶことが難しいインフラのメンテナンスやチーム開発などを学び、MVC（Model-View-Controller）モデルのWebシステム開発ができるレベルまで到達することができました。',
      '実際に業務で使う高圧バルブの管理システムの、機能追加や運用を担当しながら、日々エンジニアとして手を動かしています。',
      'DX人材育成プログラムでは、システム開発の基礎から応用まで学ぶことができ、「こんなシステムが欲しい」というおもいをカタチにする力・実行力が身につきます。',
      '色々なプロジェクトや合宿などを通じて資料作りやプレゼンの能力も向上しました！',
    ],
  },
  {
    name: '真子 正吉',
    company: '岡野バルブ製造株式会社',
    department: '経営本部VQ事業部',
    image: 'mako',
    link: 'https://www.regnio.co.jp/contents/124/',
    title: '楽しみながら技術が身に付きます！',
    contents: [
      '私は入社後に参加した新入社員研修でプログラミングに興味が湧いたことがきっかけで、Regnioさんの人材育成事業に参加しました。',
      '事前に1〜2ヶ月程度オンライン学習サイトで基礎を学び、研修に参加しました。リアルな開発現場では応用技術を活かす場面が多いため、壁にぶつかることもありますが、Regnioの皆さんに優しく指導していただいております。自社開発に加えて、他プロジェクトにも参加させていただくことで、日々知識やスキルが向上しています。',
      '一方で、みんなでランチを食べに行ったり、色々な話題でコミュニケーションを取ったりと、オフィス内はとても明るい雰囲気で、充実した日々を過ごしています。',
      '一つ一つタスクをやり遂げることで成功体験が生まれ、その結果、もっとやってやるという向上心が芽生えました！岡野バルブ製造とRegnioさんの期待に応えられるよう、これからも全力で頑張ります！',
    ],
  },
];

export const RegnioAddress =
  '〒810-0023 福岡県福岡市中央区警固2-2-23 Fukuoka Growth Next 202';
