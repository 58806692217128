import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Box } from '@material-ui/core';
import RegnioTeamFluidImages from '../../../images/gatsby-image/regnio-team-page/fluid-images';
import { ProjectTitle } from '../constants';

const Wrapper = styled.div`
  background: #f9f9f9;
`;

const Header = styled.div`
  background: linear-gradient(90deg, #1926a0 0%, #481b75 100%);
  height: 122px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ::after {
    position: absolute;
    top: 99%;
    content: '';
    width: 0;
    height: 0;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-top: 50px solid #30218b;
  }
  @media (max-width: 760px) {
    height: 168px;
  }
`;

const Title = styled.div`
  font-size: 44px;
  font-weight: bold;
  line-height: 51px;
  span {
    margin-left: 6px;
    font-weight: bold;
    font-size: 32px;
    line-height: 51px;
  }
  .small {
    font-size: 24px;
  }
  @media (max-width: 795px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 28px;
    line-height: 33px;
    span {
      font-size: 24px;
      line-height: 43px;
    }
    .small {
      font-size: 24px;
      line-height: 24px;
      margin: 7px 0;
    }
  }
`;

const FeatureWrapper = styled.div`
  max-width: 1135px;
  padding: 80px 0;
  margin: 0 auto;
  background: #f9f9f9;
`;

const FeatureBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 412px;
  background: #fff;
  @media (max-width: 1134px) {
    max-width: fit-content;
  }
`;

const ImageBox = styled.div`
  background: #fff9e4;
  height: 270px;
  display: grid;
  place-items: center;
`;

const Image = styled(Img)`
  width: 220px;
  max-height: 230px;
`;

const TextContents = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: #52463c;
  white-space: pre-wrap;
  margin: 12px 0;
  p {
    margin: 8px 0;
  }
  span {
    font-weight: 700;
  }
`;

const TextHeading = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #52463c;
  white-space: pre-wrap;
  margin: 16px 0;
  @media (max-width: 600px) {
    white-space: pre-wrap;
  }
`;

const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  @media (max-width: 1134px) {
    max-width: 95%;
    margin: 0 auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    max-width: 95%;
    margin: 0 16px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
`;

const DXFeaturePoints = () => {
  return (
    <Wrapper>
      <Header>
        <Title>
          {ProjectTitle}
          <span className="small">の</span>
          <span>ここがポイント！</span>
        </Title>
      </Header>
      <FeatureWrapper>
        <BoxContainer>
          <FeatureBox>
            <ImageBox>
              <Image
                fluid={RegnioTeamFluidImages('DataSet')}
                imgStyle={{ objectFit: 'contain' }}
              />
            </ImageBox>
            <Box p="20px 24px">
              <TextHeading>DX最先端の現場で{'\n'}実務経験を積める</TextHeading>
              <TextContents>
                Regnioは、ハード・ソフト・通信・機械学習/データ分析を一気通貫で行う会社です。
              </TextContents>
              <TextContents>
                実務の中で、ITのスキルだけでなく、プレゼン力や論理的思考なども鍛えられていきます。{' '}
              </TextContents>
            </Box>
          </FeatureBox>
          <FeatureBox>
            <ImageBox>
              <Image
                fluid={RegnioTeamFluidImages('PairProgramming')}
                imgStyle={{ objectFit: 'contain' }}
              />
            </ImageBox>
            <Box p="20px 24px">
              <TextHeading>
                深い業務理解を持つ{'\n'}人材をエンジニアに
              </TextHeading>
              <TextContents>
                実際に御社が使うシステムをベテランエンジニアの指導の元、開発します。
              </TextContents>
              <TextContents>
                実際に手を動かしてもらい、システム開発を担当していただくことで、深い業務理解を持つ人材が、自らシステムの企画・設計をすることができるようになります。{' '}
              </TextContents>
            </Box>
          </FeatureBox>
          <FeatureBox>
            <ImageBox>
              <Image
                fluid={RegnioTeamFluidImages('DataSystem')}
                imgStyle={{ objectFit: 'contain' }}
              />
            </ImageBox>
            <Box p="20px 24px">
              <TextHeading>自社で実際に使う{'\n'}システムを作れる</TextHeading>
              <TextContents>
                御社の課題解決のためのシステムの実制作を通じて、技術習得を目指していただきます。
              </TextContents>
              <TextContents>
                自社の社員で開発することで、サービス完成後の運用や、追加開発においても中長期的に大きなコスト削減へつながります。{' '}
              </TextContents>
            </Box>
          </FeatureBox>
          <FeatureBox>
            <ImageBox>
              <Image
                fluid={RegnioTeamFluidImages('Hand')}
                imgStyle={{ objectFit: 'contain' }}
              />
            </ImageBox>
            <Box p="20px 24px">
              <TextHeading>
                御社に最適な技術を{'\n'}最短で習得できる
              </TextHeading>
              <TextContents>
                {ProjectTitle}
                は、共通のカリキュラムを一律でこなす教育プログラムではありません。御社が実際に欲しいシステムを作っていくために必要な技術を、経験豊富なベテランエンジニアが選定します。
              </TextContents>
              <TextContents>
                最終的には自走できる人材に成長することを目指します。
              </TextContents>
            </Box>
          </FeatureBox>
        </BoxContainer>
      </FeatureWrapper>
    </Wrapper>
  );
};

export default DXFeaturePoints;
