import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Box } from '@material-ui/core';
import RegnioTeamFluidImages from '../../../images/gatsby-image/regnio-team-page/fluid-images';
import GradientX from '../../../images/svg/GradientX.svg';

const CompanyBody = styled.div`
  margin: 0 auto;
  padding: 80px 0;
  background: rgba(0, 124, 194, 0.04);
  position: relative;
`;

const CompanyTop = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  max-width: 1135px;
  margin: 0 auto;
`;

const CompanyBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 63px;
  @media (max-width: 850px) {
    margin: 30px 16px 0 16px;
  }
`;

const Regnio = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 32px;
  color: #52463c;
  p {
    line-height: 32px;
  }

  .regnio {
    font-size: 40px;
    line-height: 40px;
    font-weight: 500;
    span {
      font-size: 48px;
      line-height: 40px;
    }
  }
  @media (max-width: 500px) {
    .regnio {
      font-weight: 500;
      font-size: 40px;
      line-height: 40px;
    }
  }
  @media (max-width: 400px) {
    .regnio {
      font-size: 30px;
    }
  }
`;

const BottomTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

const BlueTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #0007ae;
  white-space: pre-wrap;
  max-width: 460px;
  .large {
    font-weight: bold;
    font-size: 34px;
    line-height: 51px;
    padding: 18px 0;
  }
  .content {
    font-size: 20px;
    line-height: 36px;
  }
  span {
    font-weight: 700;
  }
  @media (max-width: 850px) {
    margin-top: 28px;
    .large {
      font-weight: bold;
      font-size: 28px;
      line-height: 48px;
      padding: 14px 0;
    }
    .content {
      font-size: 18px;
      line-height: 36px;
      white-space: normal;
    }
  }
  @media (max-width: 850px) {
    margin-top: 0px;
    .large {
      font-size: 24px;
    }
    .content {
      font-size: 16px;
      line-height: 30px;
      white-space: normal;
    }
  }
`;

const Illustration = styled(Img)`
  width: 272px;
  max-width: 272px;
  margin-right: 55px;
  @media (max-width: 685px) {
    width: 220px;
    margin-right: 0;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  .plus {
    font-size: 52px;
    color: #1e89d7;
    padding: 0 6px;
  }
  .box {
    color: #1e89d7;
    padding: 33px 58px 32px 59px;
    background: #fff;
    border: 1px solid #1e89d7;
    box-sizing: border-box!important;
    border-radius: 4px;
    margin: 0 10px;
  }
  .blue-box {
    color: #fff;
    padding: 33px 58px 32px 59px;
    background: #1e89d7;
    border: 1px solid #1e89d7;
    box-sizing: border-box!important;
    border-radius: 4px;
    margin: 0 10px;
    position: relative;
  }

  @media (max-width: 850px) {
    .box {
      width: 101px;
      height: 60px;
      padding: 0;
      display: grid;
      place-items: center;
  }
    }
    .blue-box {
      padding: 21px 60px;
    }
  }
  @media (max-width: 685px) {
    flex-direction: column;
    margin: 40px 0;
  }
  @media (max-width: 400px) {
    .box {
      width: 80px;
      height: 50px;
      padding: 0;
      display: grid;
      place-items: center;
  }
  }
`;

const BottomRight = styled.div`
  display: flex;
  justify-content: center;
  flex-gap: 203px;
  height: 200px;
  @media (max-width: 685px) {
    height: auto;
  }
`;

const SvgWrapper = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: -40px;
`;

const BottomTextContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const TextHolder = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 29px;
  color: #52463c;
  white-space: pre-wrap;
  span {
    font-weight: 700;
  }
  @media (max-width: 685px) {
    white-space: normal;
    text-align: start;
    margin: 0 16px;
    font-size: 14px;
    line-height: 29px;
  }
`;

const OkanoRegnioDescription = () => {
  return (
    <CompanyBody>
      <SvgWrapper>
        <img src={GradientX} alt="gradient icon" />
      </SvgWrapper>

      <CompanyTop>
        <Regnio>
          <p className="text">最新技術を使って</p>
          <p>老舗企業のDXに取り組むITベンチャー</p>
          <p className="regnio">
            株式会社<span>Regnio</span>
          </p>
        </Regnio>
      </CompanyTop>

      <CompanyBottom>
        <BottomTop>
          <Illustration
            fluid={RegnioTeamFluidImages('illustStudy')}
            imgStyle={{ objectFit: 'contain' }}
          />
          <BlueTextContainer>
            <p className="large">
              長期的な事業変革のために{'\n'}
              人材育成が必要です。
            </p>
            <p className="content">
              私たちはこれまでのプロジェクトを通じ、{'\n'}
              長期的な事業変革には{'\n'}
              <span>
                深い業務理解と最新技術の両方を兼ね備えた人材を{'\n'}
                社内に育成
              </span>
              することが重要だと思っています。{'\n'}
            </p>
          </BlueTextContainer>
        </BottomTop>
        <BottomRight>
          <BoxContainer>
            <Box display="flex">
              <div className="box">コンサル</div>
              <div className="box">開発</div>
              <div className="box">運用</div>
            </Box>

            <div className="plus">+</div>
            <div className="blue-box">人材育成</div>
          </BoxContainer>
        </BottomRight>
      </CompanyBottom>
      <BottomTextContent>
        <TextHolder>
          株式会社Regnioは、DX戦略コンサル、開発、運用をやっていると同時に、
          {'\n'} <span>企業の内部からの進化</span>も促す為、
          <span>DX人材育成事業</span>をはじめました。
        </TextHolder>
      </BottomTextContent>
    </CompanyBody>
  );
};

export default OkanoRegnioDescription;
