import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import RegnioTeamFluidImages from '../../../images/gatsby-image/regnio-team-page/fluid-images';

const StepThreeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 100px 0;
  @media (max-width: 1134px) {
    margin: 40px 0;
  }
  @media (max-width: 750px) {
    background: #fff;
    padding: 40px 0;
    margin: 20px 0;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 44px;
  line-height: 64px;
  color: #0007ae;
  span {
    font-weight: 600;
    font-size: 48px;
    line-height: 65px;
    color: #52463c;
    position: relative;
    margin-right: 25px;
    ::before {
      content: 'STEP';
      position: absolute;
      top: 0;
      left: 20%;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
    }
  }
  @media (max-width: 750px) {
    span {
      margin-right: 8px;
    }
  }
  @media (max-width: 555px) {
    font-size: 30px;
    line-height: 32px;
    color: #0007ae;
    span {
      font-size: 24px;
      line-height: 24px;
      margin-right: 8px;
      margin: 0 8px;
      ::before {
        position: absolute;
        top: -8px;
        left: 0%;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
      }
    }
  }
  @media (max-width: 370px) {
    width: 150px;
  }
`;

const Fukidashi = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: max-content;
  padding: 24px;
  line-height: 26px;
  box-sizing: border-box !important;
  background: #1e89d7;
  border-radius: 16px;
  color: #ffffff;
  text-align: center;
  white-space: pre-wrap;
  position: relative;
  margin-right: 32px;
  ::after {
    content: '';
    position: absolute;
    margin: 0;
    bottom: -30px;
    right: 30px;
    width: 0;
    height: 0;
    border-top: 40px solid #1e89d7;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    transform: rotate(-30deg);
  }
  @media (max-width: 480px) {
    font-size: 16px;
    line-height: 23px;
    ::after {
      display: none;
    }
  }
  @media (max-width: 350px) {
    padding: 18px;
    margin-right: 8px;
  }
`;

const ScheduleBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 26px;
  color: #1e89d7;
  background: #dff2fc;
  width: 221px;
  height: 120px;
  white-space: pre-wrap;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin-right: 2px;
  @media (max-width: 900px) {
    margin-top: 2px;
    width: auto;
  }
  @media (max-width: 750px) {
    justify-content: flex-start;
    padding-left: 36px;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 29px;
    padding-left: 16px;
  }
`;

const DottedBorder = styled.div`
  border-bottom: 2px dashed #828282;
  flex-grow: 2;
  align-self: center;
  margin: 0 8px;
`;

const MonthHeading = styled.div`
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  font-feature-settings: 'halt' on;
  color: #1e89d7;
  span {
    font-size: 40px;
  }
  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 32px;
    span {
      font-size: 32px;
    }
  }
`;

const ScheduleCalender = styled(Box)`
  margin: 3px 0 13px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  font-feature-settings: 'halt' on;
  color: #828282;
`;

const Image = styled(Img)`
  width: 220px;
  margin-top: 28px;
  @media (max-width: 480px) {
    width: 150px;
  }
  @media (max-width: 350px) {
    width: 100px;
  }
`;

const TextContents = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: #52463c;
  white-space: pre-wrap;
  p {
    margin: 8px 0;
  }
  span {
    font-weight: 700;
  }
`;

const BorderFukidashi = styled.div`
  white-space: pre-wrap;
  border: 2px solid #dff2fc;
  border-radius: 16px;
  box-sizing: border-box !important;
  padding: 21px 23px;
  font-size: 18px;
  line-height: 26px;
  color: #1e89d7;
  background: #fff;
  max-width: max-content;
  max-height: 124px;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: -24px;
    left: 50%;
    margin-left: -15px;
    border: 12px solid transparent;
    border-bottom: 12px solid #fff;
    z-index: 2;
  }
  ::after {
    content: '';
    position: absolute;
    top: -30px;
    left: 50%;
    margin-left: -17px;
    border: 14px solid transparent;
    border-bottom: 14px solid #dff2fc;
    z-index: 1;
  }

  span {
    font-weight: 700;
  }
  @media (max-width: 900px) {
    margin-top: 24px;
    white-space: normal;
  }
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 29px;
    max-height: 200px;
  }
`;

const BorderFukidashiArrowRight = styled.div`
  white-space: pre-wrap;
  background: #fff;
  border: 2px solid #dff2fc;
  border-radius: 16px;
  box-sizing: border-box !important;
  padding: 21px 23px;
  font-size: 18px;
  line-height: 26px;
  color: #1e89d7;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  max-height: 124px;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: -20px;
    right: -1px;
    margin-left: -15px;
    border: 24px solid transparent;
    border-bottom: 20px solid #fff;
    z-index: 2;
    transform: rotate(135deg);
  }
  ::after {
    content: '';
    position: absolute;
    top: -25px;
    right: -5px;
    margin-left: -17px;
    border: 26px solid transparent;
    border-bottom: 22px solid #dff2fc;
    z-index: 1;
    transform: rotate(135deg);
  }

  span {
    font-weight: 700;
  }
`;

const FukudashiWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 20px;
  margin-left: auto;
`;

const SPScheduleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SPScheduleRightBox = styled.div`
  padding-left: 16px;
  border-left: 2px dashed #828282;
  margin: 10px 0 10px 10px;
  @media (max-width: 750px) {
    margin: 16px 0 16px 10px;
  }
  @media (max-width: 350px) {
    padding-left: 8px;
  }
`;

const SPTerm = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  font-feature-settings: 'halt' on;
  color: #828282;
`;

const SPTextContents = styled.div`
  padding: 16px;
  font-size: 16px;
  line-height: 26px;
  color: #52463c;
  p {
    font-weight: normal;
    width: 80%;
    margin: 8px auto;
  }
  @media (max-width: 380px) {
    padding-bottom: 0px;
  }
`;

const SPScheduleWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  @media (max-width: 500px) {
    width: 90%;
  }
  @media (max-width: 400px) {
    width: 95%;
  }
`;

const StepThree = ({ width }) => {
  const date = new Date();
  const thisYear = date.getFullYear();
  const thisMonth = new Date(date.setMonth(date.getMonth() + 3)).getMonth();
  const next6Months = new Date(date.setMonth(date.getMonth() + 6));
  const next12Months = new Date(date.setMonth(date.getMonth() + 5));

  const SPContainer = () => {
    return (
      <StepThreeWrapper>
        <Box display="flex" mx={2}>
          <Title>
            <span>03</span>
          </Title>
          <TextContents>
            <Title>DX技術者育成期間</Title>
          </TextContents>
        </Box>

        <Box my={5} display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box display="flex" alignItems="center">
              <Fukidashi>
                リグニオに{'\n'}
                人材が出向して{'\n'}
                スタート
              </Fukidashi>
              <div>
                <Image fluid={RegnioTeamFluidImages('illustCoach')} />
              </div>
            </Box>
            <SPTextContents>
              <p>
                弊社へ出向し、半年〜1年間、実際に手を動かしてもらいながら、
                {'\n'}
                システム開発を担当して頂きます。
              </p>
              <p>
                経験豊富な弊社のベテランエンジニアが、{'\n'}
                技術選定やディレクション・アドバイス・サポートを行います。
              </p>
            </SPTextContents>
            <SPScheduleWrapper>
              <Box mt={5} display="flex" flexDirection="column">
                <SPScheduleContainer>
                  <MonthHeading>
                    <div>
                      <span>6</span>ヶ月間
                    </div>
                  </MonthHeading>
                  <div>
                    <SPTerm>
                      {thisYear}年{thisMonth}月
                    </SPTerm>
                    <SPScheduleRightBox>
                      <ScheduleBox>
                        <div>・要件整理{'\n'}・技術選定</div>
                      </ScheduleBox>
                      <ScheduleBox>
                        <div>
                          ・プログラミングの基礎{'\n'}・チーム開発の基礎
                        </div>
                      </ScheduleBox>
                      <BorderFukidashi>
                        サポートを受けながら{'\n'}
                        <span>限られた機能の開発が</span>
                        {'\n'}
                        できるレベルになる！
                      </BorderFukidashi>
                    </SPScheduleRightBox>
                  </div>
                </SPScheduleContainer>

                <SPScheduleContainer>
                  <MonthHeading>
                    <div>
                      <span>6</span>ヶ月間
                    </div>
                  </MonthHeading>
                  <div>
                    <SPTerm>
                      {next6Months.getFullYear()}年{next6Months.getMonth()}月
                    </SPTerm>
                    <SPScheduleRightBox>
                      <ScheduleBox flexBasis="50%">
                        <div>・システム開発の基礎{'\n'}・設計の基礎</div>
                      </ScheduleBox>
                      <BorderFukidashi>
                        サポートを受けながら{'\n'}
                        <span>簡単なシステムの構築が</span>
                        {'\n'}
                        できるレベルになる！
                      </BorderFukidashi>
                    </SPScheduleRightBox>
                    <SPTerm>
                      {next12Months.getFullYear()}年{next12Months.getMonth()}月
                    </SPTerm>
                  </div>
                </SPScheduleContainer>
              </Box>
            </SPScheduleWrapper>
          </Box>
        </Box>
      </StepThreeWrapper>
    );
  };

  return (
    <>
      {width < 901 ? (
        <SPContainer />
      ) : (
        <StepThreeWrapper>
          <Box display="flex">
            <Title>
              <span>03</span>
            </Title>
            <TextContents>
              <Title>DX技術者育成期間</Title>
              <p>
                弊社へ出向し、半年〜1年間、実際に手を動かしてもらいながら、
                {'\n'}
                システム開発を担当して頂きます。
              </p>
              <p>
                経験豊富な弊社のベテランエンジニアが、{'\n'}
                技術選定やディレクション・アドバイス・サポートを行います。
              </p>
            </TextContents>
          </Box>

          <Box my={5} display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <Fukidashi>
                リグニオに{'\n'}
                人材が出向して{'\n'}
                スタート
              </Fukidashi>
              <Box width="80%">
                <Box display="flex" flexDirection="column">
                  <Box display="flex" flexDirection="column">
                    <MonthHeading>
                      <div>
                        <span>6</span>ヶ月間
                      </div>
                      <div>
                        <span>6</span>ヶ月間
                      </div>
                    </MonthHeading>
                    <ScheduleCalender
                      display="flex"
                      justifyContent="space-between"
                    >
                      <span>
                        {thisYear}年{thisMonth}月
                      </span>
                      <DottedBorder />
                      <span>
                        {next6Months.getFullYear()}年{next6Months.getMonth()}月
                      </span>
                      <DottedBorder />
                      <span>
                        {next12Months.getFullYear()}年{next12Months.getMonth()}
                        月
                      </span>
                    </ScheduleCalender>
                  </Box>
                  <Box display="flex">
                    <ScheduleBox flexBasis="20%">
                      <div>・要件整理{'\n'}・技術選定</div>
                    </ScheduleBox>
                    <ScheduleBox flexBasis="30%">
                      <div>・プログラミングの基礎{'\n'}・チーム開発の基礎</div>
                    </ScheduleBox>
                    <ScheduleBox flexBasis="50%">
                      <div>・システム開発の基礎{'\n'}・設計の基礎</div>
                    </ScheduleBox>
                  </Box>
                </Box>
              </Box>
            </Box>
            <FukudashiWrapper>
              <div>
                <Image fluid={RegnioTeamFluidImages('illustCoach')} />
              </div>
              <BorderFukidashi>
                サポートを受けながら{'\n'}
                <span>限られた機能の開発が</span>
                {'\n'}
                できるレベルになる！
              </BorderFukidashi>
              <BorderFukidashiArrowRight>
                サポートを受けながら{'\n'}
                <span>簡単なシステムの構築が</span>
                {'\n'}
                できるレベルになる！
              </BorderFukidashiArrowRight>
            </FukudashiWrapper>
          </Box>
        </StepThreeWrapper>
      )}
    </>
  );
};

export default StepThree;
