import React from 'react';
import styled from 'styled-components';
import { RegnioAddress } from './constants';

const Wrapper = styled.div`
  text-align: left;
  color: #4f4f4f;
  padding: 0 24px;
`;

const TextBox = styled.div`
  padding: 24px 0;
`;

const TextContents = styled.div`
  font-size: 14px;
  line-height: 25px;
`;

const Heading = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  padding-bottom: 12px;
`;

const ListContainer = styled.ol`
  padding: 8px 24px 0 24px;
`;

const DXPrivacyPolicy = () => {
  return (
    <Wrapper>
      <div>
        株式会社Regnio（以下、「当社」といいます）は、本サービスを利用する全ての方（以下「利用者」といいます）のプライバシーを尊重するために、
        個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」）を定めます。
      </div>
      <TextBox>
        <Heading>第1条 個人情報の定義</Heading>
        <TextContents>
          個人情報とは、個人情報保護法にいう「個人情報」を指し、本サービスを通じて取得した生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報等を指します。
        </TextContents>
      </TextBox>
      <TextBox>
        <Heading>第2条 利用目的</Heading>
        <TextContents>
          当社は、利用者の個人情報を以下の目的で利用することができるものとします。
          <br />
          <ListContainer type="1">
            <li>
              本サービスならびにその他本サービスに関連する情報の提供のため
            </li>
            <li>利用者の本人確認のため</li>
            <li>
              本サービスの運営上必要な事項の通知（電子メールによるものを含むものとします。）
            </li>
            <li>
              当社及び第三者の商品等の広告または宣伝（電子メールによるものを含むものとします。）
            </li>
            <li>メールマガジンの送信</li>
            <li>個人を特定できない範囲においての統計情報の作成および利用</li>
            <li>本サービスの改善や新規開発に必要なデータの解析や分析</li>
            <li>
              利用者の皆様の行動、性別、本サービス内でのアクセス履歴などを用いたターゲティング広告の配信
            </li>
            <li>契約や法律等に基づく権利の行使や義務の履行</li>
            <li>アフターサービス、各種問い合わせ対応のため</li>
            <li>上記の利用目的に付随する目的</li>
          </ListContainer>
        </TextContents>
      </TextBox>
      <TextBox>
        <Heading>第3条 個人情報利用の制限</Heading>
        <TextContents>
          当社は、個人情報保護法その他の法令により許容される場合を除き、利用者の同意を得ず、利
          用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。
        </TextContents>
        <TextContents>
          <ListContainer type="1">
            <li>
              本サービスならびにその他本サービスに関連する情報の提供のため
            </li>
            <li>法令に基づく場合</li>
            <li>
              人の生命、身体又は財産の保護のために必要がある場合であって、利用者の同意を得ることが困難であるとき
            </li>
            <li>
              公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、利用者の同意を得ることが困難であるとき
            </li>
            <li>
              国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行する
            </li>
            <li>
              国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
            </li>
          </ListContainer>
        </TextContents>
      </TextBox>
      <TextBox>
        <Heading>第4条 個人情報の適切な取得</Heading>
        <TextContents>
          当社は、当社が本サービスを提供するために必要な範囲で、利用者から提供して頂く方法又は利用者が本サービスを利用するにあたって当社が収集する方法によって、利用者の個人情報を取得します。当社は、適正に個人情報を取得し、偽りその他不正な手段等により個人情報を取得することはありません。
        </TextContents>
      </TextBox>
      <TextBox>
        <Heading>第5条 個人情報の安全管理</Heading>
        <TextContents>
          当社では、個人情報の漏洩または毀損の防止ならびにその他個人情報の安全管理が行われるよう、個人情報の適切な管理を行います
        </TextContents>
      </TextBox>
      <TextBox>
        <Heading>第6条 個人情報の開示、訂正、削除等</Heading>
        <TextContents>
          当社は、個人情報の開示、訂正、削除について、個人情報保護法その他の法令等に基づき、個人情報の開示、訂正、追加、削除、利用停止、消去、第三者提供の停止、利用目的の通知の請求に対応いたします。ただし、請求が本人確認不可能な場合や、個人情報保護法の定める要件を満たさない場合等により、ご希望に添えない場合があります。
        </TextContents>
      </TextBox>
      <TextBox>
        <Heading>第7条 プライバシーポリシーの変更</Heading>
        <TextContents>
          <ListContainer type="1">
            <li>
              本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて利用者に通知することなく変更することができるものとします。
            </li>
            <li>
              当社が別途定める場合を除いて変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
            </li>
          </ListContainer>
        </TextContents>
      </TextBox>
      <TextBox>
        <Heading>第8条 お問い合わせ窓口</Heading>
        <TextContents>
          本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。
        </TextContents>
        <TextContents>
          <div>住所：{RegnioAddress}</div>
          <div>社名：株式会社Regnio</div>
          <div>Eメールアドレス：info@regnio.co.jp</div>
        </TextContents>
      </TextBox>
      <div>2021年3月2日 制定・施行</div>
    </Wrapper>
  );
};

export default DXPrivacyPolicy;
